<template>
  <div class="group-member-list-wrapper">
    <div class="header">
      <span class="member-detail">详情</span>
      <!-- <span class="member-count text-ellipsis">群成员：{{currentConversation.groupProfile.memberCount}}</span> -->
      <!-- <popover v-model="addGroupMemberVisible">
        <add-group-member></add-group-member>
        <div slot="reference" class="btn-add-member" title="添加群成员">
          <span class="tim-icon-friend-add"></span>
        </div>
      </popover> -->
    </div>
    <div class="member-title">{{ this.currentConversation.groupProfile.name }}</div>
    <div class="member-count text-ellipsis">
      群成员：<span style="color: #999">（{{ currentConversation.groupProfile.memberCount }}）</span>
    </div>
    <div class="scroll-content">
      <div class="group-member-list">
        <div v-for="member in members" :key="member.userID">
          <!-- <popover placement="right" :key="member.userID">
            <group-member-info :member="member" /> -->
          <div slot="reference" class="group-member" @click="currentMemberID = member.userID">
            <avatar :title="getGroupMemberAvatarText(member.role)" :src="member.avatar" />
            <div class="member-nameWrap">
              <div class="member-name text-ellipsis">
                <span v-if="member.nameCard" :title="member.nameCard">{{ member.nameCard }}</span>
                <span v-else-if="member.nick" :title="member.nick">{{ member.nick }}</span>
                <span v-else :title="member.userID">{{ member.userID }}</span>
              </div>
              <div class="member-role" v-for="item in member.memberCustomField" :key="item.key">
                <span v-if="item.key === 'memberRole'">{{ item.value }}</span>
              </div>
            </div>
          </div>
          <!-- </popover> -->
        </div>
      </div>
    </div>
    <!-- <div class="group-setWrap">
      <div class="group-setTitle">群设置</div>
      <div class="group-chatWrap">
        <div class="group-txt">置顶聊天</div>
        <div class="group-switch">
          <el-switch v-model="topFlag" active-color="#009CFF"> </el-switch>
        </div>
      </div>
    </div> -->
    <div class="more">
      <el-button v-if="showLoadMore" type="text" @click="loadMore">查看更多</el-button>
    </div>
  </div>
</template>
<script>
import { Popover } from 'element-ui';
import { mapState } from 'vuex';
import AddGroupMember from './add-group-member.vue';
import GroupMemberInfo from './group-member-info.vue';
export default {
  data() {
    return {
      topFlag: true,
      addGroupMemberVisible: false,
      currentMemberID: '',
      count: 30, // 显示的群成员数量
    };
  },
  props: ['groupProfile'],
  components: {
    Popover,
    AddGroupMember,
    GroupMemberInfo,
  },
  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
      currentMemberList: (state) => state.group.currentMemberList,
    }),

    showLoadMore() {
      return this.members.length < this.groupProfile.memberCount;
    },
    members() {
      console.log(this.currentMemberList);
      return this.currentMemberList.slice(0, this.count);
    },
  },
  mounted() {},
  methods: {
    getGroupMemberAvatarText(role) {
      switch (role) {
        case 'Owner':
          return '群主';
        case 'Admin':
          return '管理员';
        default:
          return '群成员';
      }
    },
    loadMore() {
      this.$store.dispatch('getGroupMemberList', this.groupProfile.groupID).then(() => {
        this.count += 30;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.group-member-list-wrapper
  .group-setWrap
    padding:0 20px
    .group-chatWrap
      padding 0 20px
      display: flex
      justify-content :space-between
      align-items :center
      height: 50px
      border-radius: 10px
      border: 1px solid #DDDDDD
      .group-txt
        color:#333
    .group-setTitle
      font-size 14px
      color #333
      font-weight bold
      padding-bottom:20px
  .member-title
    font-weight:bold
    font-size:16px
    color:#333
    padding:20px
  .member-count
      display inline-block
      max-width 130px
      line-height 30px
      font-size 14px
      color:#333
      vertical-align bottom
      padding-left:20px
  .header
    height 70px
    padding 10px 16px 10px 20px
    border-bottom 1px solid $border-base
    .member-detail
      font-size 16px
      color #333
      padding-top 15px
      display inline-block
    .btn-add-member
      width 30px
      height 30px
      font-size 28px
      text-align center
      line-height 32px
      cursor pointer
      float right
      &:hover
        color $light-primary
  .scroll-content
    max-height: 280px;
    overflow-y: scroll;
    padding 10px 15px 10px 15px
    width 100%
    .group-member-list
      display flex
      justify-content flex-start
      flex-wrap wrap
      width 100%
    .group-member
      width 130px
      height 70px
      display: flex;
      justify-content center
      align-content center
      text-align: center;
      color: $black;
      // cursor: pointer;
      margin: 0 20px 10px 0;
      padding: 10px 0 0 0
      text-align:left
      .member-nameWrap
         margin-left 5px
        .member-name
          font-size 13px
          width: 80px
        .member-role
          color #999
        .avatar
          width 40px
          height 40px
          border-radius 50%
  .more
    padding 0 20px
    // border-bottom 1px solid $border-base

// .add-group-member {
//   cursor: pointer;
// }
// .add-button {
//   border: 1px solid gray;
//   text-align: center;
//   line-height: 30px;
// }
</style>
