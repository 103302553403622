<template>
  <div>
    <!-- <a-popover overlayClassName="classPopover" placement="bottom">
           <template slot="content">
               <div class="item-link share-title" @click="handleChineseLink">中文分享链接</div>
               <div class="item-link share-title math-link" @click="handleMathLink">数学分享链接</div>
                     
            </template>
             <div class="share-btnWrap">
                <img src="@/assets/service_msg_icon_link1.png" class="icon_enter" />
                <span class="share-title">分享链接</span>
              </div>
      </a-popover> -->
    <div style="display: flex">
      <div
        class="share-btnWrap"
        @click="() => handleCopyLink(item, index)"
        v-for="(item, index) in linkList"
        :key="index"
      >
        <a-icon v-if="copyLoadingIndex === index" type="loading" style="margin-right: 4px" />
        <span class="share-title">{{ item.title }}</span>
        <a-icon type="copy" style="margin-left: 4px" />
      </div>
    </div>

    <a-modal v-model="modalVisible" :footer="null" centered>
      <div class="share-link-title">{{ shareLinkTitle }} <span class="share-success" /></div>
      <div class="share-link-name">( {{ studentProfile.fullName }} ) 的专属链接</div>
      <div class="share-link-url">{{ shareLink }}</div>
    </a-modal>
  </div>
</template>

<script>
import { shortLinkByLong } from '@/api/common';
import { SubjectEnum } from '@/enum/index';
import { reportEvent } from '@/utils/gatherer';
import { getUserUUID } from '@/utils/user';

export default {
  name: 'sharelink',
  props: {
    studentProfile: {
      type: Object,
    },
    originType: {
      type: String,
    },
  },
  data() {
    return {
      hover: false,
      modalVisible: false,
      shareChineseLink: '',
      shareMathLink: '',
      shareLinkTitle: '',
      shareLinkName: '',
      shareLink: '',
      copyLoadingIndex: null,
      linkList: [
        {
          title: '中文1v1推荐',
          url: process.env.VUE_APP_CHINESE_POSTER_URL,
          subject: SubjectEnum.CHINESE,
          eventName: 'CAstudio_ChineseSharingLinkClick',
        },
        {
          title: '数学1v1推荐',
          url: process.env.VUE_APP_MATH_POSTER_URL,
          subject: SubjectEnum.MATH,
          eventName: 'CAstudio_MathSharingLinkClick',
        },
        {
          title: '英文1v1推荐',
          url: process.env.VUE_APP_ENGLISH_POSTER_URL,
          subject: SubjectEnum.ENGLISH,
          eventName: 'CAstudio_EnglishSharingLinkClick',
        },
      ],
    };
  },

  methods: {
    copyLink(url) {
      const input = document.createElement('input');
      input.value = url;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$message.success('已复制链接');
    },
    handleEnglishLink() {
      const url = `${process.env.VUE_APP_ENGLISH_POSTER_URL}&code=${this.studentProfile.code}`;
      this.copyLink(url);
    },
    async handleCopyLink(item, index) {
      if (this.copyLoadingIndex === index) return;

      reportEvent({
        data: {
          code: this.studentProfile.code,
          _event: item.eventName,
          User_id: getUserUUID(),
          _subject: item.subject,
        },
      });

      this.copyLoadingIndex = index;

      const { data: shortUrl } = await shortLinkByLong(item.url + '&code=' + this.studentProfile.code);

      this.copyLoadingIndex = null;
      this.copyLink(
        '我家孩子在悟空上了一段时间课了，老师很靠谱，孩子现在上课都不用我陪，课后练习也很主动。他们家学习报告那些也很好，不跟课也知道孩子的薄弱点在哪。你家孩子要不要试试？我这有免费试听的名额' +
          shortUrl,
      );
    },
  },
};
</script>

<style lang="stylus" scoped>

.share-flex
  display: flex

.share-btnWrap
  height: 36px
  background:#f7f7f7
  border-radius: 10px
  line-height: 36px
  padding: 0 10px
  cursor: pointer
  margin-right:12px
  .icon_enter
    width: 18px
    height: 18px
    margin-right: 5px
.share-poster
  display: flex;
  align-items: center;
  margin-right: 20px
  .icon_enter
    background: url('~@/assets/service_msg_icon_poster.png') no-repeat center
    background-size: 100% 100%
  &:hover
    background: rgba(67,209,134,0.1)
    span
      color: #FFFFFF
    .icon_enter
      background: url('~@/assets/service_msg_icon_poster1.png') no-repeat center
      background-size: 100% 100%
.share-title
  font-size: 13px
  font-weight: 400
  color:#333
  line-height: 18px
.share-link

  &:hover
    background: rgba(67,209,134,0.1)

.item-link
  height: 36px
  text-align: center
  line-height 36px
  cursor: pointer
  padding:0 10px
  &:hover
    background:#41CC83
    color: #FFFFFF
.math-link
  &:hover
    border-radius: 0
.share-link-title
  font-size: 18px
  font-weight: 600
  color: #333333
  line-height: 18px
  magrin-bottom: 10px
.share-link-name
  font-size: 16px
  font-weight: 400
  color: #333333
  line-height: 16px
  margin-top: 25px
  margin-bottom: 15px
.share-link-url
  font-size: 16px
  background: #F4F4F4
  border-radius: 2px
  border: 1px solid #DDDDDD
  color: #999
  min-height 30px
  padding: 0 5px
</style>
