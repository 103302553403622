<template>
  <div class="list-container">
    <div class="header-top">
      <el-input
        class="header-input"
        placeholder="请输入学生姓名"
        v-model="searchStudentName"
        @keydown.enter.native="searchByName"
      />
      <el-button class="header-btn" type="primary" @click="searchByName">搜索</el-button>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleTabClick" :stretch="stretch">
      <el-tab-pane :label="onlineLabel" name="online" v-loading="searchLoading">
        <div class="screen-wrap" v-show="showScreen">
          <div class="screen-result">筛选结果（{{ groupList.length }}）</div>
          <div class="screen-clear" @click="searchByName('clear')">清空筛选条件</div>
        </div>
        <div class="scroll-container">
          <group-item v-for="group in groupList" :key="group.groupID" :groupFirst="groupList[0]" :group="group" />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="oldLabel" name="second"></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getStudentInfo } from '@/api/headTeacher'
import { getArrEqual, getArrIsSame } from '@/utils/util'
import GroupItem from './group-item.vue'
export default {
  data() {
    return {
      groupID: '',
      hideSearchLoading: true,
      oldLabel: '曾服务学生',
      screenCount: 0,
      stretch: true,
      activeName: 'online',
      showDialog: false,
      userID: '',
      isCheckouting: false, // 是否正在切换会话
      timeout: null
    }
  },
  components: {
    GroupItem
  },
  computed: {
    groupList: function() {
      return this.$store.state.group.groupList
    },
    searchStudentName: {
      get() {
        return this.$store.state.conversation.studentName
      },
      set(val) {
        this.$store.commit('setStudentName', val)
      }
    },
    ...mapState({
      searchLoading: state => state.conversation.searchLoading,
      showScreen: state => state.conversation.showScreen,
      conversationList: state => state.conversation.conversationList,
      currentConversation: state => state.conversation.currentConversation,
      onlineLabel: state => {
        return `在服务学生（${state.conversation.totalCount}）`
      }
    })
  },
  methods: {
    handleTabClick() {},
    onGroupUpdated(groupList) {
      this.$store.dispatch('updateGroupList', groupList)
    },
    //搜索学生姓名
    searchByName(type) {
      if (type === 'clear') {
        //点击清空搜索条件
        this.$store.commit('clearSearchCon')
      } else {
        if (!this.searchStudentName) {
          this.$store.commit('showMessage', {
            message: '请输入学生姓名',
            type: 'warning'
          })
          return
        }
        this.$store.commit('setShowScreen', true)
      }
      // 去触发小悟空首页的数据处理
      this.$bus.$emit('handleStudentInfoSearch', type)
    },
    //搜索服务学生列表
    _getStudentInfo() {
      this.$store.commit('setSearchLoading', true)
      const params = {
        name: this.searchStudentName
      }

      getStudentInfo(params).then(res => {
        const data = res.data.content
        const studentList = data.list
        if (studentList.length === 0) {
          this.$store.commit('showMessage', {
            message: '没有找到该学生',
            type: 'error'
          })
          this.screenCount = 0
          this.$store.commit('updateConversationList', [])
        } else {
          let conversationList1 = []
          // 拉取会话列表
          const promise = this.tim.getConversationList()
          promise
            .then(imResponse => {
              conversationList1 = imResponse.data.conversationList.filter(item => {
                return item.type === 'GROUP'
              })
              //根据groupID去过滤掉没有群组的学生
              const list = this.getArrEqual(studentList, conversationList1)
              this.screenCount = list.length
              this.$store.commit('updateConversationList', list)
            })
            .catch(function(imError) {
              console.warn('getConversationList error:', imError) // 获取会话列表失败的相关信息
            })
        }
        this.$store.commit('setSearchLoading', false)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
/deep/ .el-input__inner
  border:none!important
  border-radius: 10px;
/deep/ .el-button--primary
  border-radius:8px;
.screen-wrap
  padding:0 0 15px
  display :flex
  justify-content :space-between
  .screen-clear
      padding:1px 6px
      color:#c3c3c3
      border:1px solid #c3c3c3
      border-radius:30px
      cursor:pointer
.header-top
    display:flex
    margin 10px 0
    border-radius: 10px;
    border: 1px solid  #DCDFE6;
.list-container
  height 85vh
  width 100%
  padding:0 20px
  display flex
  flex-direction column // -reverse
  .header-bar
    flex-shrink 0
    height 50px
    border-bottom 1px solid $background-deep-dark
    padding 10px 10px 10px 20px

  .scroll-container
    height 70vh
    overflow-y scroll
    flex 1
.bottom-circle-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.refresh {
  bottom: 70px;
}
</style>
