<template>
  <div class="list-container">
    <!-- 头部信息 -->
    <header-info-wukong />
    <div class="header-top">
      <el-input placeholder="请输入群名称" v-model="groupName" @keydown.enter.native="searchByName" />
      <el-button type="primary" @click="searchByName">搜索</el-button>

      <!-- <button title="刷新列表" @click="handleRefresh">
        <i class="tim-icon-refresh"></i>
      </button>
      <button title="创建会话" @click="handleAddButtonClick">
        <i class="tim-icon-add"></i>
      </button> -->
    </div>
    <el-tabs v-model="activeName" @tab-click="handleTabClick" :stretch="stretch">
      <el-tab-pane :label="oldLabel" name="second" v-loading="searchLoading">
        <div class="screen-wrap" v-show="showScreen">
          <div class="screen-result">
            筛选结果（{{ this.fittertoAccessList ? this.fittertoAccessList.length : 0 }}）
          </div>
          <div class="screen-clear" @click="searchByName('clear')">清空筛选条件</div>
        </div>
        <div class="scroll-container">
          <!-- 等待接入 -->
          <conversation-item-toaccess
            :conversation="item"
            v-for="item in fittertoAccessList"
            :key="item.studentId + item.groupId"
            @getToAccessList="getToAccessList"
            @handleActiveName="handleActiveName"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="在聊中" name="online" v-loading="searchLoading">
        <div class="screen-wrap" v-show="showScreen">
          <div class="screen-result">筛选结果（{{ conversationList.length }}）</div>
          <div class="screen-clear" @click="searchByName('clear')">清空筛选条件</div>
        </div>
        <div class="scroll-container">
          <!-- 在聊中 type 默认-->
          <conversation-item-wukong
            :conversation="item"
            v-for="item in conversationList"
            :key="item.conversationID"
            ref="conversationItemToaccess"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <audio hidden="hidden" preload="auto" ref="waitingAudio">
      <source :src="waitingIncoming" />
    </audio>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import ConversationItemWukong from './conversation-item-wukong';
import ConversationItemToaccess from './conversation-item-toaccess';
import HeaderInfoWukong from '../header-info-wukong';
import { REMEMBERME } from '@/store/mutation-types';
import { nameFilterAccessGroup,getArrEqualXWK,getArrIsSame} from '@/utils/util';
import { getStudentsInfo } from '@/api/xiaowukong';
import { queryStudentByGroup } from '@/api/headTeacher';
import waitingIncoming from '@/assets/IM/audio/waiting-incoming.mp3';
import { CURRENT_CONVERSATION_STUDENT } from '@/store/mutation-types';

const session = storage.createStore(sessionStorage);

export default {
  name: 'ConversationListWukong',
  components: {
    ConversationItemWukong,
    ConversationItemToaccess,
    HeaderInfoWukong,
  },
  data() {
    return {
      waitingIncoming,
      groupName: '',
      filterStudentList: [],
      stretch: true,
      showDialog: false,
      // userID: '',
      isCheckouting: false, // 是否正在切换会话
      timeout: null,
      groupID: '',
      hideSearchLoading: true,
      // 待接入的1分钟数据定时
      intervalId: null,
      // 停止接单10分钟 后停止轮训定时
      timeoutId: null,
      fittertoAccessList: [],
      groupFirstObj: {},
    };
  },
  computed: {
    sdkStudentList() {
      return session.get('sdkStudentList');
    },

    activeName: {
      get() {
        return this.$store.state.userIM.activeName;
      },
      set(val) {
        this.$store.commit('updateActiveName', val);
      },
    },
    ...mapState({
      conversationList: (state) => state.conversation.conversationList,
      currentConversation: (state) => state.conversation.currentConversation,
      userID: (state) => state.userIM.userID,
      // 待接入数据
      toAccessList: (state) => state.xiaowukong.toAccessList,
      //是否有新的待接入会话
       toAccessFlag: (state) => state.xiaowukong.toAccessFlag,
      oldLabel: (state) => {
        return `待接入（${state.xiaowukong.toAccessList ? state.xiaowukong.toAccessList.length : 0}）`;
      },
      currentStudentProfile: (state) => {
        return state.userIM.currentStudentProfile;
      },
      showScreen: (state) => state.conversation.showScreen,
      searchLoading: (state) => state.conversation.searchLoading,
    }),
  },
  mounted() {
    //避免拿不到conversationList
    setTimeout(() => {
      if (this.conversationList && this.conversationList.length > 0) {
        [this.groupFirstObj] = this.conversationList;
        // 获取右边的学生详情
        const groupId = this.groupFirstObj?.groupProfile?.groupID;
        this.queryStudentByGroup(groupId);
        this.$store.dispatch('checkoutConversation', this.groupFirstObj.conversationID);
      }
    }, 1000);

    window.addEventListener('keydown', this.handleKeydown);
    this.$bus.$on('handleConversationItemClick', this.handleConversationItemClick);
    // 接单
    this.$bus.$on('handleTakeOrdersClick', this.handleTakeOrdersClick);

    // 获取 接入数据
    this.getToAccessList();

    // 1分钟更新 接入数据
    this.handleRenovate();
  },
  beforeDestroy() {
    this.$bus.$off('handleConversationItemClick', this.handleConversationItemClick);
    this.$bus.$off('handleTakeOrdersClick', this.handleTakeOrdersClick);
    this.handleCloseRenovate();
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  watch: {
      toAccessList:{
        handler(newVal,oldVal){
        
          //判断两个数据是否有不同的
             //有新的待接入会话，增加提示音
          let flag=getArrIsSame(newVal,oldVal)
         
          if(!flag){//代表有不一样的
             this.$nextTick(()=>{
            this.$refs.waitingAudio.play()
         })
           
          }  
           this.handleFittertoAccessList()
        },
        immediate:true
      },
   
    // 发送信息框的清空操作的监听
    showScreen(newshowScreen, oldshowScreen) {
      // 输入框 清空数据 执行 待接入恢复默认数据  在聊中更新学生数据
      // showScreen 为false 并且 studentName无值 那么就清空搜索
      if (!newshowScreen && !this.groupName) {
        // console.log('触发情况')
        this.handleFittertoAccessList();
        // 去触发 小悟空首页 调用无参数的列表数据
        this.$bus.$emit('handleStudentInfoSearch');
      }
    },
  },
  methods: {
    // 其余代码同上
    handleSearch(queryString) {
      const queryStringArr = queryString.split('');
      const str = '(.*?)';
      this.filterStudentList = [];
      const regStr = str + queryStringArr.join(str) + str;
      const reg = RegExp(regStr, 'i'); // 以mh为例生成的正则表达式为/(.*?)m(.*?)h(.*?)/i
      this.sdkStudentList.forEach((item) => {
        if (reg.test(item.groupProfile.name)) {
          this.filterStudentList.push(item);
        }
      });
      this.$store.commit('updateConversationList', this.filterStudentList);
    },
    handleTabClick() {},
    // 搜索学生姓名
    searchByName(type) {
      if (type === 'clear') {
        // 点击清空搜索条件
        this.groupName = '';
        this.$store.commit('clearSearchCon');
      } else {
        if (!this.groupName) {
          this.$store.commit('showMessage', {
            message: '请输入群名',
            type: 'warning',
          });
          return;
        }
        this.$store.commit('setShowScreen', true);
      }
      // 去触发小悟空首页的数据处理
      this.handleSearch(this.groupName);

      // this.$bus.$emit('handleStudentInfoSearch', type)
      // this._getStudentInfo()
      this.handleFittertoAccessList();
    },

    handleRefresh() {
      this.refreshConversation()();
    },
    refreshConversation() {
      const that = this;
      return function () {
        if (!that.timeout) {
          that.timeout = setTimeout(() => {
            that.timeout = null;
            that.tim.getConversationList().then(() => {
              that.$store.commit('showMessage', {
                message: '刷新成功',
                type: 'success',
              });
            });
          }, 1000);
        }
      };
    },
    handleAddButtonClick() {
      this.showDialog = true;
    },
    handleConfirm() {
      // this.$store
      //   .dispatch('checkoutConversation', `GROUP${this.userID}`)
      //   .then(() => {
      //     this.showDialog = false
      //   }).catch(() => {
      //   this.$store.commit('showMessage', {
      //     message: '没有找到该用户',
      //     type: 'warning'
      //   })
      // })
    },
    handleKeydown(event) {
      if ((event.keyCode !== 38 && event.keyCode !== 40) || this.isCheckouting) {
        return;
      }
      const currentIndex = this.conversationList.findIndex(
        (item) => item.conversationID === this.currentConversation.conversationID,
      );
      if (event.keyCode === 38 && currentIndex - 1 >= 0) {
        this.checkoutPrev(currentIndex);
      }
      if (event.keyCode === 40 && currentIndex + 1 < this.conversationList.length) {
        this.checkoutNext(currentIndex);
      }
    },
    checkoutPrev(currentIndex) {
      this.isCheckouting = true;
      this.$store
        .dispatch('checkoutConversation', this.conversationList[currentIndex - 1].conversationID)
        .then(() => {
          this.isCheckouting = false;
        })
        .catch(() => {
          this.isCheckouting = false;
        });
    },
    checkoutNext(currentIndex) {
      this.isCheckouting = true;
      this.$store
        .dispatch('checkoutConversation', this.conversationList[currentIndex + 1].conversationID)
        .then(() => {
          this.isCheckouting = false;
        })
        .catch(() => {
          this.isCheckouting = false;
        });
    },
    getToAccessList() {
      // const obj = storage.get(REMEMBERME) ? storage.get('userObj') : session.get('userObj');
      const obj = storage.get('userObj');
      // console.log('getToAccessList--》',obj)
      if (obj && obj.userID) {
        const parms = {
          serverId: obj.userID,
        };
        // 获取接入接口
        this.$store.dispatch('handleToAccessList', parms);
      }
    },
    // 1分钟刷新页面
    handleRenovate() {
      // 计时器存在 退出
      if (this.intervalId != null) {
        return;
      }
      this.intervalId = setInterval(() => {
        console.log('1分钟刷新页面--》');
        this.getToAccessList();
      }, 15000);
    },
    // 关闭刷新
    handleCloseRenovate() {
      console.log('关闭 1分钟刷新页面--》');
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    // 10分钟后关闭轮训
    handleCloseInterval() {
      // 定时器存在 清空上一次10分钟的
      if (this.timeoutId != null) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      this.timeoutId = setTimeout(() => {
        this.handleCloseRenovate();
      }, 600000);
    },

    // 触发ref子 倒计时函数
    handleConversationItemClick(currentConversationid) {
      // this.currentStudentProfile
      // this.$refs.conversationItemToaccess[0].handleEndTiming()
      // this.$refs.conversationItemToaccess
      // console.log('当前选中的id',this.currentStudentProfile.conversationID)

      this.$nextTick(() => {
        const id = currentConversationid;
        const arr = this.$refs.conversationItemToaccess;
        const index = arr.findIndex((v) => v.conversation.conversationID === id);
        this.$refs.conversationItemToaccess[index].handleEndTiming();
      });
    },
    // 待接入数据过滤
    handleFittertoAccessList() {
      // console.log('handleFittertoAccessList')
      // 有搜索数据使用搜索 没有就是用原数据
      if (this.groupName) {
        const list = nameFilterAccessGroup(this.groupName, this.toAccessList);

        this.fittertoAccessList = list;
      } else {
        this.fittertoAccessList = this.toAccessList;
      }
    },
    // 停止接单 停止数据定时器 开始接单 获取数据 开始数据定时器
    handleTakeOrdersClick(data) {
      // console.log('handleTakeOrdersClick',data)
      if (data) {
        // 开始接单
        this.getToAccessList();
        this.handleRenovate();
        // 如果上次的10分钟 停止接单到计时存在 清空上次的到计时
        if (this.timeoutId != null) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
      } else {
        // 停止接单 设置10分钟定时器 10分钟后才停止刷新接口 ；如果10分钟内有点击了接单 清楚10分钟的定时器
        this.handleCloseInterval();
      }
    },
    // 查询学生信息
    queryStudentByGroup(groupId) {
      const params = {
        groupId,
      };

      queryStudentByGroup(params).then((res) => {
        const studentObj = res.data;
        this.$store.commit('updateCurrenntStudentProfile', res.data);
        const { fullName, code, uuid } = studentObj;
        const conversationStudent = {
          fullName,
          code,
          uuid,
        };
       
        // 记录选中学生信息
        session.set(CURRENT_CONVERSATION_STUDENT, conversationStudent);
      });
    },
    // 接入成功后 切换到在聊中
    handleActiveName(data) {
      this.activeName = data;
      //  this.$store.commit('updateActiveName', data)
      setTimeout(() => {
        // 获取list数据 (会不会出现数据延迟？因为会先去调学生信息 过滤后才会显示list数据 )
        if (this.conversationList && this.conversationList.length > 0) {
          // const activeConversation = this.conversationList[0];
          // //  选中最新的第一条数据
          // this.$store.commit('updateCurrenntStudentProfile', activeConversation);
          // this.$store.dispatch('checkoutConversation', activeConversation.conversationID);
          [this.groupFirstObj] = this.conversationList;
          // 获取右边的学生详情
          const groupId = this.groupFirstObj?.groupProfile?.groupID;
          this.queryStudentByGroup(groupId);
          this.$store.dispatch('checkoutConversation', this.groupFirstObj?.conversationID);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/ .el-input__inner
  border:none!important
  border-radius: 10px;
/deep/ .el-button--primary
  border-radius:8px;
.screen-wrap
  padding:0 0 15px
  display :flex
  justify-content :space-between
  .screen-clear
      padding:1px 6px
      color:#c3c3c3
      border:1px solid #c3c3c3
      border-radius:30px
      cursor:pointer
.header-top
    display:flex
    margin 10px 0
    border-radius: 10px;
    border: 1px solid  #DCDFE6;
.list-container
  height 85vh
  width 100%
  padding:0 20px
  display flex
  flex-direction column // -reverse
  .header-bar
    flex-shrink 0
    height 50px
    border-bottom 1px solid $background-deep-dark
    padding 10px 10px 10px 20px

  .scroll-container
    height: 58vh;
    overflow-y scroll
    flex 1
.bottom-circle-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.refresh {
  bottom: 70px;
}
</style>
