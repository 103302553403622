import { render, staticRenderFns } from "./text-element.vue?vue&type=template&id=5701ab69&scoped=true&"
import script from "./text-element.vue?vue&type=script&lang=js&"
export * from "./text-element.vue?vue&type=script&lang=js&"
import style0 from "./text-element.vue?vue&type=style&index=0&id=5701ab69&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5701ab69",
  null
  
)

export default component.exports