<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="sound-element-wrapper" :style="{ width: widthPercent + 'px' }" title="单击播放" @click="play">
      <audio :ref="'audio' + indexPos" :src="url" class="audio"></audio>
      <i class="audio-pause" v-show="!playState[`isShow${indexPos}`]"></i>
      <i class="audio-loading" v-show="playState[`isShow${indexPos}`]"></i>
      <span style="padding-left:6px;display:inline-block">{{ second + '"' }}</span>
    </div>
  </message-bubble>
</template>

<script>
import MessageBubble from '../message-bubble'
export default {
  name: 'SoundElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    indexPos: {
      type: Number
    }
  },
  components: {
    MessageBubble
  },
  data() {
    return {
      amr: null,
      timer: null,
      secondTime: 0
    }
  },
  computed: {
    url() {
      return this.payload.url
    },
    size() {
      return this.payload.size
    },
    second() {
      return this.payload.second
    },
    playState() {
      return this.$store.state.conversation.playStateAll
    },
    widthPercent() {
      if (this.second < 10) {
        return 50
      }
      if (this.second >= 10 && this.second < 20) {
        return 70
      }
      if (this.second >= 20 && this.second < 30) {
        return 90
      }
      if (this.second >= 30 && this.second < 40) {
        return 110
      }
      if (this.second >= 40 && this.second < 50) {
        return 130
      }
      if (this.second >= 50 && this.second <= 60) {
        return 150
      }
    }
  },
  methods: {
    play() {
      const audio = this.$refs[`audio${this.indexPos}`]
      //获取到所有语音，暂停不是当前播放的所有语音
      let allAudios = document.getElementsByClassName('audio')
      allAudios.forEach(item => {
        if (item.src !== audio.src) {
          item.pause()
          for (let key in this.playState) {
            //切换播放暂停的效果
            if (key !== `isShow${this.indexPos}`) {
              this.$set(this.playState, key, false)
            } else {
              this.$set(this.playState, key, true)
            }
          }
        }
      })
      this.secondTime = this.payload.second
      this.timer = null
      clearInterval(this.tiemr)
      // 目前移动端的语音消息采用 aac 格式，以前用 amr 格式。默认先用 audio 标签播放，若无法播放则尝试 amr 格式播放。
      // const audio = document.createElement('audio')
      audio.addEventListener('error', this.tryPlayAMR) // 播放出错，则尝试使用 AMR 播放
      // audio.src = this.url
      // console.log(audio.paused)
      if (audio.paused) {
        audio.load() //重新加载音频，免得从暂停开始播放
        const promise = audio.play()
        if (promise) {
          promise
            .then(() => {
              // 音频加载成功
              this.playState[`isShow${this.indexPos}`] = true //播放状态
              // 音频的播放需要耗时,音频播放完，切换到暂停状态的图
              if (this.timer) {
                return
              }
              this.tiemr = setInterval(() => {
                this.secondTime--
                if (this.secondTime <= 0) {
                  this.playState[`isShow${this.indexPos}`] = false //暂停状态
                  this.timer = null
                  clearInterval(this.tiemr)
                }
              }, 1000)
            })
            .catch(() => {})
        }
      } else {
        audio.pause()
        this.playState[`isShow${this.indexPos}`] = false //暂停状态
      }
    },
    tryPlayAMR() {
      try {
        const isIE = /MSIE|Trident|Edge/.test(window.navigator.userAgent)
        // amr 播放组件库在 IE 不支持
        if (isIE) {
          this.$store.commit('showMessage', {
            message: '您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器',
            type: 'warning'
          })
          return
        }
        // 动态插入 amr 播放组件库
        if (!window.BenzAMRRecorder) {
          const script = document.createElement('script')
          script.addEventListener('load', this.playAMR)
          script.src = './BenzAMRRecorder.js'
          const firstScript = document.getElementsByTagName('script')[0]
          firstScript.parentNode.insertBefore(script, firstScript)
          return
        }
        this.playAMR()
      } catch (error) {
        this.$store.commit('showMessage', {
          message: '您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器',
          type: 'warning'
        })
      }
    },
    playAMR() {
      if (!this.amr && window.BenzAMRRecorder) {
        this.amr = new window.BenzAMRRecorder()
      }
      if (this.amr.isInit()) {
        this.amr.play()
        return
      }
      this.amr.initWithUrl(this.url).then(() => {
        this.amr.play()
      })
    }
  },
  mounted() {
    if (this.playState[`isShow${this.indexPos}`] == undefined) {
      this.$set(this.playState, `isShow${this.indexPos}`, false) //起作用
    }
  }
}
</script>

<style lang="stylus" scoped>
.sound-element-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content :left
  margin-left:10px
}
.audio-loading{
  width:16px;
  height:14px;
  display:inline-block
  background: url('~@/./assets/audio.gif') no-repeat 0 0;
  background-size: cover;
}
.audio-pause{
 width:16px;
  height:14px;
  display:inline-block
  background: url('~@/./assets/pauseIcon.png') no-repeat 0 0;
  background-size: cover;
}
</style>
