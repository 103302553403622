import axios from 'axios';
import { fetch,post } from '@/api';

const { VUE_APP_API_IM_URL } = process.env;
const PUBLIC_BASE_URL = process.env.VUE_APP_API_PUBLIC_GATEWAY_URL;
const userApi = {
  posterList: '/public/ref/poster_images',
  posterQrcode: '/api/wechat/share/qr_code_image',
  form: '/public/form/quizName',
  queryExpandSubjectPoster:'/api/reseller/expand_subject/poster',
  getExpandPosterQrcode:'/api/wechat/share/qr_code_image/expand_subject',
  batchDownloadExpand:'/api/wechat/share/batch_download/expand_subject',
  batchDownloadExpandNew:'/api/wechat/share/batch_download',
  queryWaterMarkCode:'/api/wechat/share/watermark_code'

};

export const getPosterList = (subject, parameter) => {
  return fetch(VUE_APP_API_IM_URL, `${userApi.posterList}/${subject}`, parameter);
};

export const getPosterQrcode = (parameter) => {
  return axios.get(`${VUE_APP_API_IM_URL}${userApi.posterQrcode}`, { params: parameter, responseType: 'arraybuffer' });
};

export const getFormListByName = (parameter) => {
  return fetch(PUBLIC_BASE_URL, `${userApi.form}/${parameter}`);
};
//拓科海报查询
export function queryExpandSubjectPoster(parameter) {
  return fetch(VUE_APP_API_IM_URL, userApi.queryExpandSubjectPoster, parameter);
}
//拓科海报下载

export const getExpandPosterQrcode = (parameter) => {
  return axios.get(`${VUE_APP_API_IM_URL}${userApi.getExpandPosterQrcode}`, { params: parameter, responseType: 'arraybuffer' });
};
// 批量下载海报
export function batchDownloadExpand(parameter, config) {
  return axios.post(`${VUE_APP_API_IM_URL}${userApi.batchDownloadExpand}`, parameter, config);
}
// 批量下载海报新
export function batchDownloadExpandNew(parameter) {
  return post(VUE_APP_API_IM_URL,userApi.batchDownloadExpandNew, parameter);
}
// 获取海报水印码
export function queryWaterMarkCode(parameter) {
  return post(VUE_APP_API_IM_URL,userApi.queryWaterMarkCode, parameter);
}