<template>
  <div
    @click="handleGroupClick"
    class="conversation-item-container"
    :class="{ choose: `GROUP${group.groupID}` === currentConversation.conversationID }"
  >
    <!-- <div class="group-item">
      <avatar :src="group.headImageUrl" />
      <div class="group-name text-ellipsis">{{ group.fullName }}</div>
    </div> -->
    <div class="warp">
      <avatar :src="group.headImageUrl" />
      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text-ellipsis">
              <span>{{ group.fullName }} </span>
            </div>
          </div>
          <!-- <div class="unread-count">
            <span class="badge" v-if="showUnreadCount">
              {{ conversation.unreadCount > 99 ? '99+' : conversation.unreadCount }}
            </span>
          </div> -->
        </div>
        <div class="row-2">
          <div class="summary">
            <span class="timezone_greywrap"> </span>
            <span class="timezone_txt text-ellipsis" :title="group.timezone">{{
              getTimezoneName(group.timezone)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sessionStorage from 'store/storages/sessionStorage';
import { CURRENT_CONVERSATION_STUDENT } from '@/store/mutation-types';
import storage from 'store';
const session = storage.createStore(sessionStorage);
import { mapState } from 'vuex';
import {
  getTimezoneName
} from '@/utils/geoLocation';
export default {
  props: ['group', 'groupFirst'],
  data() {
    return {
      getTimezoneName,
      visible: false,
      options: [
        {
          text: '退出群组',
          handler: this.quitGroup,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
    }),
  },
  mounted() {
    const conversationID = `GROUP${this.groupFirst.groupID}`;

    //不打开聊天界面
    this.$store.dispatch('checkoutConversation', conversationID);
    this.$store.commit('updateCurrenntStudentProfile', this.groupFirst);
    const { fullName, code, uuid } = this.groupFirst;
    const conversation_student = {
      fullName,
      code,
      uuid,
    };
    // 记录选中学生信息
    session.set(CURRENT_CONVERSATION_STUDENT, conversation_student);
  },
  methods: {
    handleGroupClick() {
      const conversationID = `GROUP${this.group.groupID}`;

      //不打开聊天界面
      this.$store.dispatch('checkoutConversation', conversationID);
      this.$store.commit('updateCurrenntStudentProfile', this.group);
      const { fullName, code, uuid } = this.group;
      const conversation_student = {
        fullName,
        code,
        uuid,
      };
      // 记录选中学生信息
      session.set(CURRENT_CONVERSATION_STUDENT, conversation_student);
    },
    quitGroup() {
      this.tim.quitGroup(this.group.groupID).catch((error) => {
        this.$store.commit('showMessage', {
          type: 'error',
          message: error.message,
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.timezone_grey
  width 100%
  height 100%
.conversation-item-container
  padding 14px 12px
  cursor pointer
  position relative
  border-radius 14px
  overflow hidden
  transition .2s
  // &:first-child
  //   padding-top 30px
  &:hover
    background-color #F0F9FF
    .close-btn
      right 3px
  .close-btn
    position absolute
    right -20px
    top 3px
    color $font-dark
    transition: all .2s ease;
    &:hover
      color $danger
  .warp
    display flex
  .avatar
    width 54px
    height 54px
    margin-right 10px
    border-radius 50%
    flex-shrink 0
  .content
    flex 1
    height 40px
    overflow hidden
    .row-1
      display flex
      line-height 21px
      .name
        color $font-light
        flex 1
        min-width 0px
      .unread-count
        padding-left 10px
        flex-shrink 0
        color $font-dark
        font-size 12px
        .badge
          vertical-align bottom
          background-color $danger
          border-radius 10px
          color #FFF
          display inline-block
          font-size 12px
          height 18px
          max-width 40px
          line-height 18px
          padding 0 6px
          text-align center
          white-space nowrap
    .row-2
      font-size 12px
      padding-top 3px
      .summary
        overflow hidden
        min-width 0px
        color: $secondary
        display flex
        align-items center
        .timezone_greywrap
          display inline-block
          width 12px
          height 12px
          background url('../../../assets/service_icon_timezone_grey.png') no-repeat
          background-size 100%
          margin-right 2px
        .timezone_txt
          display inline-block
        .remind
          color $danger
      .date
        padding-left 10px
        flex-shrink 0
        text-align right
        color $font-dark
.choose {
  background-color:#C8E8FD;
}
.context-menu-button {
  padding: 10px
  border: 2px solid $primary;
  border-radius: 8px;
}
// .scroll-container
//   overflow-y scroll
//   flex 1
//   .group-item
//     display flex
//     padding 10px 20px
//     cursor pointer
//     position relative
//     overflow hidden
//     transition .2s
//     &:hover
//       background-color $background
//     .avatar
//       width 30px
//       height 30px
//       border-radius 50%
//       margin-right 10px
//       flex-shrink 0
//     .group-name
//       flex 1
//       color $font-light
//       line-height 30px
</style>
