<template>
  <div class="list-container">
    <div class="header-top">
      <el-input
        :clearable=true
        class="header-input"
        placeholder="请输入群名"
        v-model="groupName"
        @keydown.enter.native="searchByName"
      />
      <el-button class="header-btn" type="primary" @click="searchByName">搜索</el-button>

      <!-- <button title="刷新列表" @click="handleRefresh">
        <i class="tim-icon-refresh"></i>
      </button>
      <button title="创建会话" @click="handleAddButtonClick">
        <i class="tim-icon-add"></i>
      </button> -->
    </div>

    <div class="screen-wrap" v-show="showScreen">
      <div class="screen-result">筛选结果（{{ conversationList.length }}）</div>
      <div class="screen-clear" @click="searchByName('clear')">清空筛选条件</div>
    </div>
    <div class="scroll-container">
      <conversation-item :conversation="item" v-for="item in conversationList" :key="item.groupID" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { queryStudentByGroup } from '@/api/headTeacher';
import ConversationItem from './conversation-item';
import { CURRENT_CONVERSATION_STUDENT } from '@/store/mutation-types';

const session = storage.createStore(sessionStorage);
export default {
  name: 'ConversationList',
  components: {
    ConversationItem,
  },
  data() {
    return {
      groupFirstObj: {},
      oldLabel: '曾服务学生',
      screenCount: 0,
      stretch: true,
      activeName: 'online',
      showDialog: false,
      userID: '',
      isCheckouting: false, // 是否正在切换会话
      timeout: null,
      groupID: '',
      hideSearchLoading: true,
      filterStudentList: [],
      showScreen: false,
    };
  },
  computed: {
    sdkStudentList() {
      return session.get('sdkStudentList');
    },
    groupName: {
      get() {
        return this.searchGroupName;
      },
      set(val) {
        this.$store.commit('setGroupName', val);
      },
    },

    ...mapState({
      searchLoading: (state) => state.conversation.searchLoading,
      conversationList: (state) => {
        return state.conversation.conversationList;
      },
      groupIdRoute: (state) => state.conversation.groupIdRoute,
      searchGroupName: (state) => state.conversation.searchGroupName, // 来自主页弹窗去沟通
      currentConversation: (state) => state.conversation.currentConversation,
      onlineLabel: (state) => {
        return `在服务学生（${state.conversation.totalCount}）`;
      },
    }),
  },
  mounted() {
  console.log(this.conversationList)
    // 如果从表格学生点击进来，显示筛选结果
    if (this.searchGroupName) {
      this.showScreen = true;
      this.searchByName();
    } else {
      this.showScreen = false;
    }
       let flag=false//默认没有会话,
    // 进入我的群会话列表，默认选中聊天列表的第一个，并展示学生信息
    if (this.groupIdRoute) {
   
      this.conversationList.forEach((item) => {
        if (item.groupProfile.groupID === this.groupIdRoute) {
          flag=true
          this.groupFirstObj = item;
        }
      });
    } else {
  
      [this.groupFirstObj] = this.conversationList;
      console.log(this.conversationList)
      flag=true
    }
    //有群但是没有会话
    if(!flag){
    this.queryStudentByGroup(this.groupIdRoute);
    this.$store.dispatch('checkoutConversation',`GROUP${this.groupIdRoute}`);
    }else{
 // 获取右边的学生详情
 console.log(this.groupFirstObj)
    const groupId = this.groupFirstObj?.groupProfile?.groupID;

    this.queryStudentByGroup(groupId);
    this.$store.dispatch('checkoutConversation', this.groupFirstObj?.conversationID);
    }
   
    window.addEventListener('keydown', this.handleKeydown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown);
  },

  methods: {
    // 查询学生信息
    queryStudentByGroup(groupId) {
      const params = {
        groupId,
      };
      queryStudentByGroup(params).then((res) => {
        const studentObj = res.data;
        this.$store.commit('updateCurrenntStudentProfile', res.data);
        const { fullName, code, uuid } = studentObj;
        const conversationStudent = {
          fullName,
          code,
          uuid,
        };
        // 记录选中学生信息
        session.set(CURRENT_CONVERSATION_STUDENT, conversationStudent);
      });
    },
    // 其余代码同上
    handleSearch(queryString) {
      const queryStringArr = queryString.split('');
      const str = '(.*?)';
      this.filterStudentList = [];
      const regStr = str + queryStringArr.join(str) + str;
      const reg = RegExp(regStr, 'i'); // 以mh为例生成的正则表达式为/(.*?)m(.*?)h(.*?)/i
      this.sdkStudentList.forEach((item) => {
        if (reg.test(item.groupProfile.name)) {
          this.filterStudentList.push(item);
        }
      });
      this.$store.commit('updateConversationList', this.filterStudentList);
    },
    handleTabClick() {},
    // 搜索学生姓名
    searchByName(type) {
      if (type === 'clear') {
        this.$store.commit('setGroupName', '');
        this.showScreen = false;
      } else {
        if (!this.searchGroupName) {
          this.$store.commit('showMessage', {
            message: '请输入群名',
            type: 'warning',
          });
          return;
        }
        this.showScreen = true;
      }
      this.handleSearch(this.searchGroupName);
    },

    handleRefresh() {
      this.refreshConversation()();
    },
    refreshConversation() {
      const that = this;
      return function () {
        if (!that.timeout) {
          that.timeout = setTimeout(() => {
            that.timeout = null;
            that.tim.getConversationList().then(() => {
              that.$store.commit('showMessage', {
                message: '刷新成功',
                type: 'success',
              });
            });
          }, 1000);
        }
      };
    },
    handleAddButtonClick() {
      this.showDialog = true;
    },

    handleKeydown(event) {
      if ((event.keyCode !== 38 && event.keyCode !== 40) || this.isCheckouting) {
        return;
      }
      const currentIndex = this.conversationList.findIndex(
        (item) => item.conversationID === this.currentConversation.conversationID,
      );
      if (event.keyCode === 38 && currentIndex - 1 >= 0) {
        this.checkoutPrev(currentIndex);
      }
      if (event.keyCode === 40 && currentIndex + 1 < this.conversationList.length) {
        this.checkoutNext(currentIndex);
      }
    },
    checkoutPrev(currentIndex) {
      this.isCheckouting = true;
      this.$store
        .dispatch('checkoutConversation', this.conversationList[currentIndex - 1].conversationID)
        .then(() => {
          this.isCheckouting = false;
        })
        .catch(() => {
          this.isCheckouting = false;
        });
    },
    checkoutNext(currentIndex) {
      this.isCheckouting = true;
      this.$store
        .dispatch('checkoutConversation', this.conversationList[currentIndex + 1].conversationID)
        .then(() => {
          this.isCheckouting = false;
        })
        .catch(() => {
          this.isCheckouting = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/ .el-input__inner
  border:none!important
  border-radius: 10px;
/deep/ .el-button--primary
  border-radius:8px;
  background-color:#41CC83;
  border-color:#41CC83;
.screen-wrap
  padding:0 0 15px
  display :flex
  justify-content :space-between
  .screen-clear
      padding:1px 6px
      color:#c3c3c3
      border:1px solid #c3c3c3
      border-radius:30px
      cursor:pointer
.header-top
    display:flex
    margin 10px 0
    border-radius: 10px;
    border: 1px solid  #DCDFE6;
.list-container
  height 85vh
  width 100%
  padding:0 20px
  display flex
  flex-direction column // -reverse
  .header-bar
    flex-shrink 0
    height 50px
    border-bottom 1px solid $background-deep-dark
    padding 10px 10px 10px 20px

  .scroll-container
    height 70vh
    overflow-y scroll
    flex 1
.bottom-circle-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.refresh {
  bottom: 70px;
}
</style>
