import axios, { post, fetch } from '@/api';
import { getUploadOssToken } from '@/api/headTeacher';
import { imgpostUrl } from '@/api/register';

const baseURl = process.env.VUE_APP_API_GATEWAY_HOST;
export const getReplayLink = (uuid) => axios.get(`${baseURl}/cp-scheduling/api/classroom/${uuid}/records`, {});

const BASE_URL = process.env.VUE_APP_API_IM_URL;

export const uploadFile = async (file) => {
  const res = await getUploadOssToken();
  const formData = new FormData();
  const formArray = Object.entries(res.data.content.formFields);
  formArray.forEach((item) => {
    formData.append(item[0], item[1]);
  });
  formData.append('file', file);

  return imgpostUrl(`https://${res.data.content.endpoint}`, formData).then((res) => {
    return res.data.url;
  });
};

export const shortLinkByLong = async (url) => {
  return fetch(BASE_URL, '/public/short_by_long', { url });
};
