<template>
<message-bubble :isMine=isMine :message=message>
  <div class="custom-element-wrapper">
    <div class="survey"  v-if="this.payload.data === 'survey'">
      <div class="title">对IM DEMO的评分和建议</div>
      <el-rate
          v-model="rate"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}">
      </el-rate>
      <div class="suggestion">{{this.payload.extension}}</div>
    </div>
    <div class="conversation-down"  v-else-if="this.payload.data === 'askFinishWuKongJunior'">
      <div >请问是否已解决你的问题：</div>
      <div class="conversation-down-text">
        <span>已解决</span>
        <span>未解决，我还有问题咨询</span>
      </div>
      <!-- <el-rate
          v-model="rate"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}">
      </el-rate>
      <div class="suggestion">{{this.payload.extension}}</div> -->
    </div>
    <div class="askIMRobot"  v-else-if="this.payload.data === 'askIMRobot'">
      <div >{{text}}</div>
      <div class="askIMRobot-btn" >
        <div>
           • 转接小悟空聊天
        </div>
        <div>
          为您提供专属服务，24小时在线
        </div>
      </div>
    </div>
    <div class="student-reportWrap" v-else-if="this.payload.data === 'studentReport'">
      <div class="report-top">
        <div class="report-course">
          <img v-if="text.courseDetail.subject==='MATH'" src="@/assets/math-icon.png" class="report-icon" />
          <img v-else src="@/assets/chinese-icon.png" class="report-icon" />

          <span class="report-unit">{{text.courseDetail.scheduleName}}</span>
        </div>
        <div class="report-time">{{text.courseDetail.startTime.substring(0,10)}}</div>
      </div>
      <div class="report-title">{{text.courseDetail.sectionName}}</div>
      <div class="report-subtitle">
        <div class="left-line"></div>
        <div class="subtitle">上课情况</div>
        <div class="right-line"></div>
      </div>
      <div class="report-content">
        <!-- <div class="star-wrap">
        
             <img
                      v-for="index of text.ratingComment.ratingScore"
                      :key="index"
                      src="@/assets/star_light.png"
                     class="report_star"
                    />
                    <img
                      v-for="index of 5 - text.ratingComment.ratingScore"
                      src="@/assets/star_grey.png"
                     class="report_star"
                      :key="index"
                    />

        </div> -->
      <div class="learning-content">
        <div class="learning-item">
          <div class="learning-top">
            <span class="learning-redtxt">{{text.activeData.classDuration}} </span>
            <span class="learning-smalltxt">min</span>
          </div>
           <div class="learning-bottom">
            <span class="learning-smalltxt">本次共学习</span>
          </div>
        </div>
          <div class="learning-item">
          <div class="learning-top">
            <span class="learning-redtxt">{{text.classPerformance.award?text.classPerformance.award:0}} </span>
          </div>
           <div class="learning-bottom">
            <span class="learning-smalltxt">获得奖励数</span>
          </div>
        </div>
          <div class="learning-item">
          <div class="learning-top">
            <span class="learning-redtxt">{{text.classPerformance.accuracy>0?`${Math.round(text.classPerformance.accuracy*100)}%`:'-'}}</span>
          
          </div>
           <div class="learning-bottom">
            <span class="learning-smalltxt">答题率</span>
          </div>
        </div>
      </div>
      </div>
      <div class="thumbs-upwrap">
        <img src="@/assets/thumbs-up.png" class="thumbs-up"><span class="thumbs-uptxt">宝贝上台互动了 {{text.activeData.interactiveCount}} 次，本节课表现优秀！</span>
      </div>
      <div class="look-report" @click="hrefClass">查看课节详情<a-icon type="right" style="color:#43D186;margin-left:10px;font-weight:bold" /></div>
    </div>
    <span class="text" title="您可以自行解析自定义消息" v-else>
      <template v-if="text.isFromGroupLive && text.isFromGroupLive === 1">
        <message-group-live-status :liveInfo='text' />
      </template>
      <template v-else>{{text}}</template>
    </span>
  </div>
</message-bubble>
</template>

<script>
import { mapState } from 'vuex'
import MessageBubble from '../message-bubble'
import { Rate } from 'element-ui'
import MessageGroupLiveStatus from '../message-group-live-status'
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';

const session = storage.createStore(sessionStorage);

export default {
  name: 'CustomElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  },
  mounted(){
      console.log('payload00>',this.payload)
  },
  components: {
    MessageBubble,
    ElRate: Rate,
    MessageGroupLiveStatus
  },
  computed: {
    ...mapState({
      currentUserProfile: state => state.userIM.currentUserProfile
    }),
    text() {
      return this.translateCustomMessage(this.payload)
    },
    rate() {
      return parseInt(this.payload.description)
    }
  },
  methods: {
   hrefClass() {
     console.log(this.text.courseDetail)
     //跳转课表详情
      const conversation_student = {
        fullName:this.text.courseDetail.studentName,
        code:this.text.courseDetail.student.code,
        uuid:this.text.courseDetail.studentId,
        timezoneStd:this.text.courseDetail.student.timezoneStd,
        timezone:this.text.courseDetail.student.timezone,
        classAdmin:this.text.courseDetail.student.classAdmin,
        email:this.text.courseDetail.student.email
      };
      // 记录选中学生信息
    session.set('studentProfile',conversation_student);
      this.$router.push({ name: 'ClassCalendarAdmin',params:{date:this.text.courseDetail.startTime}});
    },
    
    translateCustomMessage(payload) {
      let videoPayload = {}
      try{
        videoPayload = JSON.parse(payload.data)
      } catch(e) {
        videoPayload = {}
      }
      if (payload.data === 'group_create') {
        return `${payload.extension}`
      }
      if(payload.data === 'askIMRobot') {
          return payload.description
      }
      if(payload.data === 'studentReport') {
        console.log(JSON.parse(payload.description))
          return JSON.parse(payload.description)
      }
      if (videoPayload.roomId) {
        videoPayload.roomId = videoPayload.roomId.toString()
        videoPayload.isFromGroupLive = 1
        return videoPayload
      }
      if(payload.text) {
        return payload.text
      }else{
        return '[自定义消息]'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.text
  font-weight bold
.title
  font-size 16px
  font-weight 600
  padding-bottom 10px
.survey
  background-color white
  color black
  padding 20px
  display flex
  flex-direction column
.suggestion
  padding-top 10px
  font-size 14px
.conversation-down{
  .conversation-down-text{
    span{
      display inline-block
      padding 2px 6px
      // background: #009CFF;
      border 1px solid #fff;
      border-radius 4px
      &:nth-child(1){
        margin-right 6px
      }
    }


  }
}
.askIMRobot-btn
  margin-top 10px
.report-icon{
  width:16px;
  height:16px;
  margin-right:6px;
}
.report-top{
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 0;
    align-items: center;
}
.report-course{
  font-size: 12px;
}
.report-unit{
  color: rgba(0, 0, 0, 0.5);
 
}
.report-time{
   color: rgba(0, 0, 0, 0.3);
}
.report-title{
  color: #333333;
  font-size: 16px;
  text-align: center;
  margin:16px 0;
}

.left-line{
  width: 100px;
  height: 1px;
}
.report-subtitle{
    display:flex;
  margin-bottom: 16px;
    align-items: center;
    justify-content: space-around;
}
.student-reportWrap{
 
}
.report-content{
  background-color: #f9f9f9;
    margin: 0 16px;
    padding: 20px 0;
    border-radius: 6px;
}
.star-wrap{
  text-align: center;
  margin-bottom: 20px;
}
.report_star{
  width:28px;
  height:32px;
  margin-right:15px;
}
.learning-content{
  display: flex;
}
.learning-item{
  flex:1;
  text-align: center
}
.learning-redtxt{
  font-size: 24px;
  color:#FF5353
}
.learning-smalltxt{
  font-size:12px;
  color:rgba(0, 0, 0, 0.5)
}
.learning-top{
  margin-bottom: 4px;
}
.thumbs-up{
  width: 28px;
  height:28px;
  padding-right:13px;
}
.thumbs-upwrap{
     padding: 2px 20px;
    border: 1px solid rgba(0,0,0,0.06);
    margin: 16px 17px;
    border-radius: 50px;
}
.thumbs-uptxt{
  font-size: 12px
  color:rgba(0, 0, 0, 0.5)
}
.look-report{
  padding:16px 16px 10px;
  text-align: center;
  color:#43D186;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  font-weight:bold;
  cursor pointer
}
.left-line{
  width:100px;
  height:1px;
    background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.15));

}
.right-line{
  width:100px;
  height:1px;
    background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.15));


}
.report_star:last-child{
  margin-right: 0;
}
</style>
