<template>
  <div
    class="conversation-item-container"
    :class="{ choose: conversation.conversationID === currentConversation.conversationID }"
    @click="selectConversation"
  >
    <!-- <div class="close-btn">
        <span class="tim-icon-close" title="删除会话" @click="deleteConversation"></span>
      </div> -->
    <div class="warp">
      <avatar :src="avatar" :type="conversation.type" />
      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text-ellipsis">
              <span
                :title="conversation.userProfile.nick || conversation.userProfile.userID"
                v-if="conversation.type === TIM.TYPES.CONV_C2C"
                >{{ conversation.userProfile.nick || conversation.userProfile.userID }}
              </span>
              <span
                :title="conversation.groupProfile.name || conversation.groupProfile.groupID"
                v-else-if="conversation.type === TIM.TYPES.CONV_GROUP"
                >{{ conversation.groupProfile.name || conversation.groupProfile.groupID }}
              </span>
              <!-- <span
                  v-else-if="conversation.type === TIM.TYPES.CONV_SYSTEM"
                  >系统通知
                </span> -->
            </div>
          </div>
          <div class="unread-count">
            <span class="badge" v-if="showUnreadCount">
              {{ conversation.unreadCount > 99 ? '99+' : conversation.unreadCount }}
            </span>
          </div>
        </div>

        <div class="row-2">
          <div class="summary">
            <div v-if="conversation.lastMessage" class="text-ellipsis">
              <span class="remind" style="color: red" v-if="hasMessageAtMe">[有人提到我]</span>
              <span class="text" :title="conversation.lastMessage.messageForShow">
                {{ messageForShow }}
              </span>
            </div>
          </div>
          <div class="date">
            {{ date }}
          </div>
        </div>
      </div>
    </div>
      <!-- <audio preload="auto|metadata|none" hidden="hidden" ref="chatAudio">
      <source :src="chatIncoming" />
    </audio> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import sessionStorage from 'store/storages/sessionStorage';
import storage from 'store';
import { isToday, getDate, getTime } from '@/utils/IM/date';
import { queryStudentByGroup } from '@/api/headTeacher';
import {  } from '@/store/mutation-types';
import chatIncoming from '@/assets/IM/audio/chat-incoming.wav';

const session = storage.createStore(sessionStorage);

export default {
  name: 'ConversationItem',
  props: ['conversation'],
  data() {
    return {
      popoverVisible: false,
      hasMessageAtMe: false,
      groupId: '',
      groupFirstObj: {},
      chatIncoming
    };
  },
  computed: {
    showUnreadCount() {
     
      if (this.$store.getters.hidden) {
        return this.conversation.unreadCount > 0;
      }
      return (
        this.currentConversation.conversationID !== this.conversation.conversationID &&
        this.conversation.unreadCount > 0
      );
    },
    date() {
      if (!this.conversation.lastMessage || !this.conversation.lastMessage.lastTime) {
        return '';
      }
      const date = new Date(this.conversation.lastMessage.lastTime * 1000);
      if (isToday(date)) {
        return getTime(date);
      }
      return getDate(date);
    },
    avatar() {
      switch (this.conversation.type) {
        case 'GROUP':
          return this.conversation.groupProfile.avatar;
        case 'C2C':
          return this.conversation.userProfile.avatar;
        default:
          return '';
      }
    },
    conversationName() {
      if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
        return this.conversation.userProfile.nick || this.conversation.userProfile.userID;
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_GROUP) {
        return this.conversation.groupProfile.name || this.conversation.groupProfile.groupID;
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_SYSTEM) {
        return '系统通知';
      }
      return '';
    },
    showGrayBadge() {
      if (this.conversation.type !== this.TIM.TYPES.CONV_GROUP) {
        return false;
      }
      return this.conversation.groupProfile.selfInfo.messageRemindType === 'AcceptNotNotify';
    },
    messageForShow() {
      if (this.conversation.lastMessage.isRevoked) {
        if (this.conversation.lastMessage.fromAccount === this.currentUserProfile.userID) {
          return '你撤回了一条消息';
        }
        if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
          return '对方撤回了一条消息';
        }

        return `${this.conversation.lastMessage.fromAccount}撤回了一条消息`;
      }
      return this.conversation.lastMessage.messageForShow;
    },
    ...mapState({
       currentStudentProfile: (state) => {
        return state.userIM.currentStudentProfile;
      },
      currentConversation: (state) => state.conversation.currentConversation,
      currentUserProfile: (state) => state.userIM.currentUserProfile,
      groupIdRoute: (state) => state.conversation.groupIdRoute, // 来自主页列表点击去沟通学生的群
      conversationList: (state) => {
        return state.conversation.conversationList;
      },
    }),
    headImageUrl: function () {
      return this.currentStudentProfile.headImageUrl || 'https://cdnwukong.com/images/public/wukong-student.png';
      },
    ...mapGetters(['toAccount']),
  },
  mounted() {},

  methods: {
    // 查询学生信息
    queryStudentByGroup(groupId) {
      const params = {
        groupId,
      };
      queryStudentByGroup(params).then((res) => {
        const studentObj = res.data;
        this.$store.commit('updateCurrenntStudentProfile', res.data);
        const { fullName, code, uuid,timezoneStd,timezone,mobile,email} = studentObj;
        const conversationStudent = {
          fullName,
          code,
          uuid,
          timezoneStd,
          timezone,
          mobile,
          email,
          headImageUrl:this.headImageUrl
        };
        // 记录选中学生信息
    //   session.set(CURRENT_CONVERSATION_STUDENT, conversationStudent);
         // 记录选中学生信息
        session.set('studentProfile', conversationStudent);
      });
    },
    selectConversation() {
      if (this.conversation.conversationID !== this.currentConversation.conversationID) {
        // 获取学生详情
        this.groupId = this.conversation.groupProfile.groupID;
        this.$store.commit('setGroupIdRoute', this.groupId);

        this.queryStudentByGroup(this.groupId);
        // 打开聊条会话的
        this.$store.dispatch('checkoutConversation', this.conversation.conversationID);
      }
    },
    deleteConversation(event) {
      // 停止冒泡，避免和点击会话的事件冲突
      event.stopPropagation();
      this.tim
        .deleteConversation(this.conversation.conversationID)
        .then(() => {
          this.$store.commit('showMessage', {
            message: `会话【${this.conversationName}】删除成功!`,
            type: 'success',
          });
          this.popoverVisible = false;
          this.$store.commit('resetCurrentConversation');
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            message: `会话【${this.conversationName}】删除失败!, error=${error.message}`,
            type: 'error',
          });
          this.popoverVisible = false;
        });
    },
    showContextMenu() {
      this.popoverVisible = true;
    },
  },
  watch: {
    //有新的未读消息进来，增加提示音
  // 'conversation.unreadCount':{
  //    handler(newVal){
  //      if(this.showUnreadCount){
  //        this.$nextTick(()=>{
  //           this.$refs.chatAudio.play()
  //        })
        
  //      }
  //    },
  //    immediate:true//立即执行，等到变化才去执行
  //  },
    currentConversation(next) {
      if (next.conversationID === this.conversation.conversationID) {
        this.hasMessageAtMe = false;
      }
    },
    conversationFirst(newVal) {
      this.$store.dispatch('checkoutConversation', newVal.conversationID);
    },
  },
};
</script>

<style lang="stylus" scoped>

.timezone_grey
  width 100%
  height 100%
.conversation-item-container
  padding 14px 12px
  cursor pointer
  position relative
  border-radius 14px
  overflow hidden
  transition .2s
  // &:first-child
  //   padding-top 30px
  &:hover
    background-color #F7F7F7
    .close-btn
      right 3px
  .close-btn
    position absolute
    right -20px
    top 3px
    color $font-dark
    transition: all .2s ease;
    &:hover
      color $danger
  .warp
    display flex
  .avatar
    width 54px
    height 54px
    margin-right 10px
    border-radius 50%
    flex-shrink 0
  .content
    flex 1
    height 40px
    overflow hidden
    .row-1
      display flex
      line-height 21px
      .name
        color $font-light
        flex 1
        min-width 0px
      .unread-count
        padding-left 10px
        flex-shrink 0
        color $font-dark
        font-size 12px
        .badge
          vertical-align bottom
          background-color $danger
          border-radius 10px
          color #FFF
          display inline-block
          font-size 12px
          height 18px
          max-width 40px
          line-height 18px
          padding 0 6px
          text-align center
          white-space nowrap
    .row-2
      display flex
      font-size 12px
      padding-top 3px
      .summary
        flex 1
        overflow hidden
        min-width 0px
        color: $secondary
        .remind
          color $danger
      .date
        padding-left 10px
        flex-shrink 0
        text-align right
        color $font-dark
.choose {
  background-color:#F7F7F7;
}
.context-menu-button {
  padding: 10px
  border: 2px solid $primary;
  border-radius: 8px;
}
</style>
