<template>
  <div class="conversation-item-container">
    <div class="warp">
      <avatar :src="avatar" :type="conversation.type" />
      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text-ellipsis">
              <span>
                {{ conversation.groupName }}
              </span>
            </div>
          </div>
          <div class="unread-count">
            {{ waitTime }}
          </div>
        </div>
        <div class="row-2">
          <div class="summary">
          </div>
          <div class="date">
            <el-button type="primary" @click="selectConversation" :loading="loading">接入</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { addGroupxiaowukong } from '@/api/xiaowukong';
// import { isToday, getDate, getTime } from '@/utils/IM/date'
import pic_head_group_Chinese from '@/assets/icons/pic_head_group_Chinese@2x.png';

export default {
  name: 'ConversationItemToaccess',
  props: ['conversation'],
  data() {
    return {
      popoverVisible: false,
      hasMessageAtMe: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userID: (state) => state.userIM.userID,
      userinfoserver: (state) => state.user.userinfoserver,
    }),
    ...mapGetters(['toAccount']),
    waitTime() {
      if (this.conversation.waitTime === 0) {
        return '刚刚';
      }
      return `等待${this.conversation.waitTime}分钟`;
    },
    avatar() {
      return this.conversation.avatar;
      // 目前没有type字段
      // switch (this.conversation.type) {
      //   case 'GROUP':
      //     return pic_head_group_Chinese
      //   case 'C2C':
      //     return this.conversation.userProfile.avatar
      //   default:
      //     return ''
      // }
    },
  },

  methods: {
    selectConversation() {
      const parms = {
        studentId: this.conversation.studentId,
        serverId: this.userID,
        groupId: this.conversation.groupId,
        groupName: this.conversation.groupName,
        studentName: this.conversation.studentName,
        serverName: this.userinfoserver.fullName,
      };
      this.loading = true;
      addGroupxiaowukong(parms)
        .then((res) => {
          // console.log('addGroupxiaowukong-res',res)
          if (res.status === 200) {
            // 更新再聊接口
            this.$store.commit('showMessage', {
              message: '接入成功',
            });
            // 更新待接入列表数据
            this.$emit('getToAccessList');
            // 切换到再聊中
            this.$emit('handleActiveName', 'online');
            // 更新学生列表数据 会话列表更新了 会触发更新数据的事件
          } else {
            this.$store.commit('showMessage', {
              message: '操作失败',
              type: 'error',
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
      // if (this.conversation.conversationID !== this.currentConversation.conversationID) {
      //   //更改当前学生信息
      //   //   this.$store.state.userIM.currentStudentProfile = this.conversation
      //   this.$store.commit('updateCurrenntStudentProfile', this.conversation)
      //   //把已读的气泡强行清空
      //   this.conversation.unreadCount = 0
      //   this.$store.dispatch('checkoutConversation', this.conversation.conversationID)
      // }
    },
  },
  watch: {
    // currentConversation(next) {
    //   if (next.conversationID === this.conversation.conversationID) {
    //     this.hasMessageAtMe = false
    //   }
    // }
  },
};
</script>

<style lang="stylus" scoped>


.conversation-item-container
  padding 15px 12px
  cursor pointer
  position relative
  overflow hidden
  border-radius 14px
  transition .2s
  // &:first-child
  //   padding-top 30px
  &:hover
    background-color #F0F9FF
    .close-btn
      right 3px
  .close-btn
    position absolute
    right -20px
    top 3px
    color $font-dark
    transition: all .2s ease;
    &:hover
      color $danger
  .warp
    display flex
  .avatar
    width 54px
    height 54px
    margin-right 10px
    border-radius 50%
    flex-shrink 0
  .content
    flex 1
    height 54px
    overflow hidden
    .row-1
      display flex
      line-height 21px
      .name
        color $font-light
        flex 1
        min-width 0px
      .unread-count
        padding-left 10px
        flex-shrink 0
        color $font-dark
        font-size 12px
        .badge
          vertical-align bottom
          background-color $danger
          border-radius 10px
          color #FFF
          display inline-block
          font-size 12px
          height 18px
          max-width 54px
          line-height 18px
          padding 0 6px
          text-align center
          white-space nowrap
    .row-2
      display flex
      font-size 12px
      padding-top 3px
      .summary
        flex 1
        overflow hidden
        min-width 0px
        color: $secondary
        .remind
          color $danger
      .date
        padding-left 10px
        flex-shrink 0
        text-align right
        color $font-dark
        .el-button {
          padding:5px 20px
        }
.choose {
  background-color:#c8e8fd;
}
.context-menu-button {
  padding: 10px
  border: 2px solid $primary;
  border-radius: 8px;
}
</style>
