<template>
  <div
    class="conversation-item-container"
    :class="{ choose: conversation.conversationID === currentConversation.conversationID }"
    @click="selectConversation"
  >
    <!-- <div class="close-btn">
        <span class="tim-icon-close" title="删除会话" @click="deleteConversation"></span>
      </div> -->
    <div class="warp">
      <div class="avatar-box">
        <avatar :type="conversation.type" :src="avatar" />
        <div class="unread-count">
          <span class="badge" v-if="showUnreadCount">
            <!-- {{ conversation.unreadCount > 99 ? '99+' : conversation.unreadCount }} -->
            ···
          </span>
        </div>
      </div>

      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text-ellipsis">
              <span
                :title="conversation.userProfile.nick || conversation.userProfile.userID"
                v-if="conversation.type === TIM.TYPES.CONV_C2C"
                >{{ conversation.userProfile.nick || conversation.userProfile.userID }}
              </span>
              <span
                :title="conversation.groupProfile.name || conversation.groupProfile.groupID"
                v-else-if="conversation.type === TIM.TYPES.CONV_GROUP"
              >
                <!-- {{ conversation.fullName || conversation.groupProfile.groupID }} -->
                {{ groupname }}
              </span>
              <!-- <span
                  v-else-if="conversation.type === TIM.TYPES.CONV_SYSTEM"
                  >系统通知
                </span> -->
            </div>
          </div>
          <!-- <div class="unread-count">
            <span class="badge" v-if="showUnreadCount">
              {{ conversation.unreadCount > 99 ? '99+' : conversation.unreadCount }}
            </span>
          </div> -->
        </div>
        <div class="row-2">
          <div class="summary">
            <div v-if="conversation.lastMessage" class="text-ellipsis">
              <span class="remind" style="color: red" v-if="hasMessageAtMe">[有人提到我]</span>
              <span class="text" :title="conversation.lastMessage.messageForShow">
                {{ messageForShow }}
              </span>
            </div>
          </div>
          <div class="date">
            {{ date }}
          </div>
        </div>
      </div>
    </div>
    <!-- <audio preload="auto" hidden="hidden" ref="chatAudio">
      <source :src="chatIncoming" />
    </audio> -->
  </div>
</template>

<script>
import chatIncoming from '@/assets/IM/audio/chat-incoming.wav';

import { mapGetters, mapState } from 'vuex';
import sessionStorage from 'store/storages/sessionStorage';
import storage from 'store';
import { handleRefundGroup } from '@/api/xiaowukong';
import pic_head_group_Chinese from '@/assets/icons/pic_head_group_Chinese@2x.png';
import { queryStudentByGroup } from '@/api/headTeacher';
import { CURRENT_CONVERSATION_STUDENT } from '@/store/mutation-types';
const session = storage.createStore(sessionStorage);
export default {
  name: 'ConversationItemWukong',
  props: ['conversation', 'type'],
  data() {
    return {
      chatIncoming,
      popoverVisible: false,
      hasMessageAtMe: false,
      intervalId: null,
    };
  },
  computed: {
    showUnreadCount() {
      if (this.$store.getters.hidden) {
        return this.conversation.unreadCount > 0;
      }
      return (
        this.currentConversation.conversationID !== this.conversation.conversationID &&
        this.conversation.unreadCount > 0
      );
    },
    date() {
      // 判断最后一天信息fromAccount是自己 就不显示等待时间 ；最后信息是用户发的 就显示等待时间
      if (!this.conversation.lastMessage || !this.conversation.lastMessage.lastTime) {
        return '';
      }
      if (this.conversation.lastMessage.fromAccount !== this.userID) {
        const date = new Date(this.conversation.lastMessage.lastTime * 1000);
        const momentdate = this.$moment(date).fromNow(true);
        return momentdate === '几秒' ? '刚刚' : `等待 ${momentdate}`;
      }
      return '';

      // const date = new Date(this.conversation.lastMessage.lastTime * 1000)
      // if (isToday(date)) {
      //   return getTime(date)
      // }
      // return getDate(date)
    },
    avatar() {
      switch (this.conversation.type) {
        case 'GROUP':
          return this.conversation.groupProfile.avatar;
        case 'C2C':
          return this.conversation.userProfile.avatar;
        default:
          return '';
      }
    },
    conversationName() {
      if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
        return this.conversation.userProfile.nick || this.conversation.userProfile.userID;
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_GROUP) {
        return this.conversation.groupProfile.name || this.conversation.groupProfile.groupID;
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_SYSTEM) {
        return '系统通知';
      }
      return '';
    },
    showGrayBadge() {
      if (this.conversation.type !== this.TIM.TYPES.CONV_GROUP) {
        return false;
      }
      return this.conversation.groupProfile.selfInfo.messageRemindType === 'AcceptNotNotify';
    },
    messageForShow() {
      if (this.conversation.lastMessage.isRevoked) {
        if (this.conversation.lastMessage.fromAccount === this.currentUserProfile.userID) {
          return '你撤回了一条消息';
        }
        if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
          return '对方撤回了一条消息';
        }
        return `${this.conversation.lastMessage.fromAccount}撤回了一条消息`;
      }
      return this.conversation.lastMessage.messageForShow;
    },
    groupname() {
      // conversation
      const groupName = this.conversation.groupProfile.groupCustomField.filter((item) => {
        return item.key === 'groupName';
      });
      return (groupName && groupName.length > 0 && groupName[0].value) || this.conversation.groupProfile.name;
    },
    ...mapState({
      userID: (state) => state.userIM.userID,
      currentConversation: (state) => state.conversation.currentConversation,
      currentUserProfile: (state) => state.userIM.currentUserProfile,
    }),
    ...mapGetters(['toAccount']),
  },
  mounted() {
    this.$bus.$on('new-messsage-at-me', (event) => {
      if (
        event.data.conversationID === this.conversation.conversationID &&
        this.conversation.conversationID !== this.currentConversation.conversationID
      ) {
        this.hasMessageAtMe = true;
      }
    });
    // console.log('conversation item wukong ->',this.conversation)
  },

  beforeDestroy() {
    this.handleCloseRenovate();
  },
  methods: {
    // 查询学生信息
    _queryStudentByGroup() {
      const params = {
        groupId: this.groupId,
      };
      queryStudentByGroup(params).then((res) => {
        const studentObj = res.data;
        this.$store.commit('updateCurrenntStudentProfile', res.data);
        const { fullName, code, uuid } = studentObj;
        const conversation_student = {
          fullName,
          code,
          uuid,
        };
        // 记录选中学生信息
        session.set(CURRENT_CONVERSATION_STUDENT, conversation_student);
      });
    },
    selectConversation() {
      if (this.conversation.conversationID !== this.currentConversation.conversationID) {
        // 获取学生详情
        this.groupId = this.conversation.groupProfile.groupID;
        this._queryStudentByGroup();

        // 打开聊天会话面板
        this.$store.dispatch('checkoutConversation', this.conversation.conversationID);
      }
    },
    deleteConversation(event) {
      // 停止冒泡，避免和点击会话的事件冲突
      event.stopPropagation();
      this.tim
        .deleteConversation(this.conversation.conversationID)
        .then(() => {
          this.$store.commit('showMessage', {
            message: `会话【${this.conversationName}】删除成功!`,
            type: 'success',
          });
          this.popoverVisible = false;
          this.$store.commit('resetCurrentConversation');
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            message: `会话【${this.conversationName}】删除失败!, error=${error.message}`,
            type: 'error',
          });
          this.popoverVisible = false;
        });
    },
    showContextMenu() {
      this.popoverVisible = true;
    },
    handleEndTiming() {
      console.log('处理5分钟倒计时-->');
      // 清除上一次
      this.handleCloseRenovate();
      this.intervalId = setTimeout(() => {
        // 退群里面我用的群信息用的当前会话的信息 不应该 应该用数据列表里面的信息
        console.log('退群-->');
        // TUDO
        if (this.conversation.type === 'GROUP') {
          // 群名称
          const groupName = this.conversation.groupProfile.groupCustomField.filter((item) => {
            return item.key === 'groupName';
          });
          // 调用退群接口
          const parms = {
            groupName: groupName[0].value || this.conversation.groupProfile.name,
            groupId: this.conversation.groupProfile.groupID,
            status: 'RESOLVE_YES',
          };
          handleRefundGroup(parms).then((res) => {
            // console.log('handleRefundGroup-res',res)
            if (res.status === 200) {
              this.$store.commit('showMessage', {
                message: `已退出${groupName[0].value || this.conversation.groupProfile.name}`,
              });
            } else {
              this.$store.commit('showMessage', {
                message: `退出${groupName[0].value || this.conversation.groupProfile.name}失败`,
                type: 'error',
              });
            }
          });
        } else {
          console.log('不是群 无法退出', this.currentConversation);
        }
      }, 300000);
    },
    // 关闭刷新
    handleCloseRenovate() {
      // console.log('清楚5分钟计时')
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
  },
  watch: {
  //  'conversation.unreadCount':{
  //    handler(newVal){
  //      if(this.showUnreadCount){
  //        this.$nextTick(()=>{
  //           this.$refs.chatAudio.play()
  //        })
       
  //      }
      
  //    },
  //    immediate:true
  //  },
    currentConversation(next) {
      if (next.conversationID === this.conversation.conversationID) {
        this.hasMessageAtMe = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>


.conversation-item-container
  padding 15px 12px
  cursor pointer
  position relative
  overflow hidden
  transition .2s
  border-radius 14px
  // &:first-child
  //   padding-top 30px
  &:hover
    background-color #F7F7F7
    .close-btn
      right 3px
  .close-btn
    position absolute
    right -20px
    top 3px
    color $font-dark
    transition: all .2s ease;
    &:hover
      color $danger
  .warp
    display flex
    .avatar-box
      position relative
      width 54px
      height 54px
      margin-right 10px
      .avatar
        width 54px
        height 54px
        // margin-right 10px
        border-radius 50%
        flex-shrink 0
      .unread-count
        position absolute
        top -4px
        left 40px
        .badge
          display inline-block
          width 20px
          height 14px
          line-height 14px
          text-align center
          background: #009CFF;
          border-radius: 7px;
          color #fff
          font-size 12px
  .content
    flex 1
    height 54px
    overflow hidden
    .row-1
      display flex
      line-height 21px
      .name
        color $font-light
        flex 1
        min-width 0px
      // .unread-count
      //   padding-left 10px
      //   flex-shrink 0
      //   color $font-dark
      //   font-size 12px
      //   .badge
      //     vertical-align bottom
      //     background-color $danger
      //     border-radius 10px
      //     color #FFF
      //     display inline-block
      //     font-size 12px
      //     height 18px
      //     max-width 54px
      //     line-height 18px
      //     padding 0 6px
      //     text-align center
      //     white-space nowrap
    .row-2
      display flex
      font-size 12px
      padding-top 3px
      .summary
        flex 1
        overflow hidden
        min-width 0px
        color: $secondary
        .remind
          color $danger
      .date
        padding-left 10px
        flex-shrink 0
        text-align right
        color $font-dark
        .el-button {
          padding:5px 20px
        }
.choose {
  background-color:#F7F7F7;
}
.context-menu-button {
  padding: 10px
  border: 2px solid $primary;
  border-radius: 8px;
}
</style>
