<template>
  <div class="header-info-wrapper">
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
        <div class="info-box-left">
          <div class="info-box-left-img">
            <img alt="头像" :src="avatarIMSrc" />
          </div>
          <div class="info-box-left-info">
            <div class="info-box-left-info-name">
              {{ userinfoserver.fullName }}
            </div>
            <div class="info-box-left-info-miaoshu">
              小悟空
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10" class="info-box-reight">
        <span class="info-box-reght-center-takeOrder" :class="{ notakeOrders: !takeOrders }">
          {{ takeOrders ? '接单' : '停止接单' }}
        </span>

        <el-switch
          v-model="takeOrders"
          active-color="#13ce66"
          inactive-color="#999999"
          @change="handleStopOrder($event)"
        >
        </el-switch>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { handleUpdateorder } from '@/api/xiaowukong'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
export default {
  name: 'HeaderInfoWukong',
  data() {
    return {
      takeOrders: true
    }
  },
  computed: {
    ...mapState({
      userID: state => state.userIM.userID,
      userinfoserver: state => state.user.userinfoserver,
      currentUserProfile: state => {
        return state.userIM.currentUserProfile
      },
      currentUserAvatar: state => state.userIM.currentUserAvatar
    }),

    avatarIMSrc() {
      return this.currentUserProfile.avatar ? this.currentUserProfile.avatar : this.currentUserAvatar
    }
  },
  watch: {
    // 每次刷新都需要调接单 避免没有登录的时候 打开页面 没有接单
    userID: function() {
      this.handlechange(true)
    }
  },
  methods: {
    handleStopOrder(val) {
      const flag = this.takeOrders //保存点击之后v-model的值
      if (val) {
        this.handlechange(val)
      } else {
        this.takeOrders = !this.takeOrders //保持点击之前的状态
        Modal.confirm({
          title: '你确认要停止接单吗',
          content: (
            <div>
              <p>停止接单后，系统将不再给您派发新的聊天咨询，在工作时间停止接单可能会影响您的绩效考核，请谨慎操作</p>

              <div style="position: absolute;bottom: 20px;left: 58px;">
                <input type="checkbox" ref="selchat" />
                同时结束所有对话
              </div>
            </div>
          ),
          okText: '停止接单',
          cancelText: '取消',
          centered: true,
          class: 'wukong',
          onOk: () => {
            flag ? (this.takeOrders = true) : (this.takeOrders = false) // 根据flag的值开启或关闭开关
            const checked = this.$refs.selchat.checked

            this.handlechange(flag, checked)
          },
          onCancel: () => {
            this.takeOrders = true
          }
        })
      }
    },

    handlechange(val, checked) {
      console.log(checked)
      // 传入点击时候的会话id
      if (!this.userID) {
        this.takeOrders = false
        return
      }
      const params = {
        id: this.userID,
        type: val ? 'online' : 'offline',
        batch: checked ? checked : false
      }
      handleUpdateorder(params).then(res => {
        if (res.status === 200) {
          this.$bus.$emit('handleTakeOrdersClick', this.takeOrders)
        } else {
          this.takeOrders = !takeOrders
          this.$store.commit('showMessage', {
            message: '操作失败',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>

// .header-info-wrapper{
//         background-color :#fff;
//         border-radius: 30px;
//         height: 140px;
//         padding :10px;
//         .info-box{
//             display :flex;
//             .info-box-left{
//                 width :60px;
//                 height :60px;
//                 margin-right :20px;
//                 img{
//                     border-radius: 50%;
//                     width: 60px;
//                     height: 60px;
//                 }
//             }
//             .info-box-reght{
//                 display :flex;
//                 flex-direction  : column;
//                 justify-content :space-around;
//                 .info-box-reght-name{

//                     font-size: 24px;
//                     font-weight: 600;
//                     color: #333333;
//                     // line-height: 40px;
//                     margin-right :16px;
//                 }
//                 .info-box-reght-gender{
//                     img{
//                         width: 20px;
//                         height: 20px;
//                         margin-right :18px;
//                     }
//                 }
//                 .infoboxreghtwork{
//                     padding :2px 6px;
//                     height: 25px;
//                     line-height :25px;
//                     background: rgba(0, 207, 100, 0.1);
//                     border-radius: 13px;
//                     color :#00CF64;
//                     >span{
//                         display inline-block
//                         padding-left: 18px;
//                         background-image: url("../../assets/icons/service_icon_arrange_can.svg");
//                         background-repeat: no-repeat;
//                         background-size: 14px 14px;
//                         background-position: left 52%;
//                     }
//                 }
//                 .worktype{
//                     background: rgba(255, 191, 0, 0.1);
//                     color :#FFBF00;
//                     >span{
//                         background-image: url("../../assets/icons/service_icon_arrange_leave (1).svg");
//                     }
//                 }
//                 .info-box-reght-center{
//                     font-size: 16px;

//                 }
//                 .info-box-reght-center-takeOrder{
//                     font-size: 16px;
//                     color :#00CF64;
//                     margin-left :12px;
//                 }
//                 .notakeOrders{
//                    color: #999999;
//                 }
//             }
//         }
// }
.info-box-left{
    display: flex
    .info-box-left-img{
        width :60px;
        height :60px;
        margin-right :10px;
        img{
            border-radius: 50%;
            width: 60px;
            height: 60px;
        }
    }
    .info-box-left-info{
        .info-box-left-info-name{
            font-size: 20px;
            font-weight: 600;
            color: #333333;
        }
        .info-box-left-info-miaoshu{
            font-size: 16px;
            color: #999999;
        }
    }
}
.info-box-reight{
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 64px;
    >span{
        margin-right :10px;
    }
}
.info-box-reght-center-takeOrder{
    font-size: 16px;
    color :#00CF64;
    margin-left :12px;
}
.notakeOrders{
    color: #999999;
}
</style>
