<template>
  <div
    @click="handleGroupClick"
    class="conversation-item-container"
    :class="{ choose: group.uuid === currentStudentID }"
  >
    <!-- <div class="group-item">
      <avatar :src="group.headImageUrl" />
      <div class="group-name text-ellipsis">{{ group.fullName }}</div>
    </div> -->
    <div class="warp">
      <avatar :src="group.headImageUrl" />
      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text-ellipsis">
              <span>{{ group.fullName }} </span>
            </div>
          </div>
          <!-- <div class="unread-count">
            <span class="badge" v-if="showUnreadCount">
              {{ conversation.unreadCount > 99 ? '99+' : conversation.unreadCount }}
            </span>
          </div> -->
        </div>
        <div class="row-2">
          <!-- <span
            class="student-flag"
            :class="[tag === '数学新生' ? 'student-flag1' : '', tag === '中文新生' ? 'student-flag2' : '']"
            v-for="(tag, index) in group.tags"
            :key="index"
            >#{{ tag }}
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sessionStorage from 'store/storages/sessionStorage';
import storage from 'store';
import { mapState } from 'vuex';
import { CURRENT_CONVERSATION_STUDENT } from '@/store/mutation-types';

const session = storage.createStore(sessionStorage);

export default {
  props: ['group'],
  data() {
    return {
      visible: false,
      groupFirst: {},
    };
  },

  computed: {
    ...mapState({
      currentStudentID: (state) => state.student.currentStudentID,
      studentIdRoute: (state) => state.student.studentIdRoute, // 来自主页列表点击学生姓名
      studentList: (state) => state.student.studentList,
    }),
  },
  mounted() {
    // 进入我的学生列表，默认选中学生
    if (this.studentIdRoute) {
      this.studentList.forEach((item) => {
        if (item.uuid === this.studentIdRoute) {
          this.groupFirst = item;
        }
      });
    } else {
      this.groupFirst = this.studentList[0];
    }

    const studentID = this.groupFirst.uuid;
    this.$store.commit('updateCurrentStudentID', studentID);
    this.$store.commit('updateStudentProfile', this.groupFirst);
    const { fullName, code, uuid,timezoneStd,timezone,mobile,classAdmin,email} = this.groupFirst;
    const conversation_student = {
      fullName,
      code,
      uuid,
      timezoneStd,
      timezone,
      mobile,
      classAdmin,
      email
    };
    // 记录选中学生信息
    session.set(CURRENT_CONVERSATION_STUDENT, conversation_student);
    session.set('studentProfile',conversation_student);
  },
  methods: {
    handleGroupClick() {
      const studentID = this.group.uuid;
      // 不打开聊天界面
      this.$store.commit('updateCurrentStudentID', studentID);
       console.log(this.group)
      this.$store.commit('updateStudentProfile', this.group);
      const { fullName, code, uuid,timezoneStd,timezone,classAdmin,email} = this.group;
      const conversation_student = {
        fullName,
        code,
        uuid,
        timezoneStd,
        timezone,
        classAdmin,
        email
      };
      // 记录选中学生信息
      session.set(CURRENT_CONVERSATION_STUDENT, conversation_student);
     session.set('studentProfile',conversation_student);
    },
  },
  beforeDestroy() {
    // 清空表格选中学生的记录
    this.$store.commit('setStudentIdRoute', '');
  },
};
</script>

<style lang="stylus" scoped>
.timezone_grey
  width 100%
  height 100%
.conversation-item-container
  margin-bottom 10px
  padding 12px
  width 280px
  height 64px
  cursor pointer
  position relative
  border-radius 16px
  overflow hidden
  transition .2s
  // &:first-child
  //   padding-top 30px
  &:hover
    background-color #F7F7F7
    .close-btn
      right 3px
  .close-btn
    position absolute
    right -20px
    top 3px
    color $font-dark
    transition: all .2s ease;
    &:hover
      color $danger
  .warp
    display flex
    align-items center
  .avatar
    width 40px
    height 40px
    margin-right 10px
    border-radius 50%
    flex-shrink 0
  .content
    flex 1

    overflow hidden
    .row-1
      display flex
      line-height 21px
      .name
        color $font-light
        flex 1
        min-width 0px
      .unread-count
        padding-left 10px
        flex-shrink 0
        color $font-dark
        font-size 12px
        .badge
          vertical-align bottom
          background-color $danger
          border-radius 10px
          color #FFF
          display inline-block
          font-size 12px
          height 18px
          max-width 40px
          line-height 18px
          padding 0 6px
          text-align center
          white-space nowrap
    .row-2
      font-size 12px
      padding-top 3px
      .summary
        overflow hidden
        min-width 0px
        color: $secondary
        display flex
        align-items center
        .timezone_greywrap
          display inline-block
          width 12px
          height 12px
          background url('../../../assets/service_icon_timezone_grey.png') no-repeat
          background-size 100%
          margin-right 2px
        .timezone_txt
          display inline-block
        .remind
          color $danger
      .date
        padding-left 10px
        flex-shrink 0
        text-align right
        color $font-dark
.choose {
  background-color:#F7F7F7;
}
.context-menu-button {
  padding: 10px
  border: 2px solid $primary;
  border-radius: 8px;
}
.student-flag{
  border-radius :12px;
  border:1px solid #333333;
  padding:1px 6px;
  display :inline-block;
  margin-right:5px;

}
.student-flag1{
   border:1px solid #009CFF;
   color:#009CFF;
}
.student-flag2{
   border:1px solid #FFBF00;
   color:#ffbf00
}
</style>
