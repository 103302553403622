<template>
  <div class="current-conversation-wrapper">
    <!--不打开聊天界面-->
    <!-- <div class="no-chatWrap">
      <div class="no-chatImg">
        <img src="@/assets/IM/image/pic_home_upgrade.png" />
      </div>
      <div class="nochat-txt">聊天功能即将上线</div>
      <div class="nochat-txt1">敬请期待</div>
    </div> -->
    <div class="current-conversation" @scroll="onScroll" v-if="showCurrentConversation">
      <div class="header">
        <div class="name">{{ name }}</div>
        <!-- 小悟空 结束会话 -->
        <div
          v-if="showHeaderConversationDown && this.currentConversation.type === 'GROUP'"
          class="header-conversation-down"
          :class="conversationDownLoading ? 'header-conversation-down-display' : ''"
          @click="handleConversationDown"
        >
          <span class="header-conversation-down-cion"> </span>
          {{ conversationDownLoading ? '请等待喔' : '结束会话' }}
        </div>
        <div class="people-count">
          <span class="people-icon"></span>
          <span class="people-num">{{
            currentConversation.groupProfile && currentConversation.groupProfile.memberCount
          }}</span>
        </div>
        <div
          class="btn-more-info"
          :class="showConversationProfile ? '' : 'left-arrow'"
          @click="showMore"
          v-show="!currentConversation.conversationID.includes('SYSTEM')"
          title="查看详细信息"
        ></div>
      </div>
      <div class="content">
        <div class="message-list" ref="message-list" @scroll="this.onScroll">
          <div class="more" v-if="!isCompleted">
            <el-button
              type="text"
              @click="$store.dispatch('getMessageList', currentConversation.conversationID)"
            >查看更多</el-button
            >
          </div>
          <div class="no-more" v-else>没有更多了</div>
          <message-item
            v-for="(message, index) in currentMessageList"
            :indexPos="index"
            :key="message.ID"
            :message="message"
          />
        </div>
        <div v-show="isShowScrollButtomTips" class="newMessageTips" @click="scrollMessageListToButtom">
          回到最新位置
        </div>
      </div>
      <div class="footer" v-if="showMessageSendBox">
        <message-send-box />
      </div>
    </div>
    <div class="profile" v-if="showConversationProfile">
      <conversation-profile />
    </div>
    <!-- 群成员资料组件 -->
    <!-- <member-profile-card /> -->
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import MessageSendBox from '../message/message-send-box'
import MessageItem from '../message/message-item'
import ConversationProfile from './conversation-profile.vue'
import MemberProfileCard from '../group/member-profile-card'
import { handleCustomizeExitInformation } from '@/api/xiaowukong'
export default {
  name: 'CurrentConversation',
  components: {
    MessageSendBox,
    MessageItem,
    ConversationProfile,
    MemberProfileCard
  },
  props: ['showHeaderConversationDown', 'showAllGroupName'],
  data() {
    return {
      isShowScrollButtomTips: false,
      preScrollHeight: 0,
      showConversationProfile: false,
      timeout: '',
      conversationDownLoading: false,
      timer: null
    }
  },
  computed: {
    ...mapState({
      currentConversation: state => {
        return state.conversation.currentConversation
      },
      currentUnreadCount: state => state.conversation.currentConversation.unreadCount,
      currentMessageList: state => state.conversation.currentMessageList,
      isCompleted: state => state.conversation.isCompleted,
      userID: state => state.userIM.userID
    }),
    ...mapGetters(['toAccount', 'hidden', 'currentConversationType']),
    // 是否显示当前会话组件
    showCurrentConversation() {
      //展示IM功能把这个去掉
      // return false
      return !!this.currentConversation.conversationID
    },
    name() {
      if (this.currentConversation.type === 'C2C') {
        return this.currentConversation.userProfile.nick || this.toAccount
      } else if (this.currentConversation.type === 'GROUP') {
        // 群名称
        // showAllGroupName 显示群完整名称 小悟空需要
        if (this.showAllGroupName) {
          const data = this.currentConversation.groupProfile.groupCustomField.filter(item => {
            return item.key === 'groupName'
          })
          return (
            (data && data.length && data.length > 0 && data[0].value) ||
            this.currentConversation.groupProfile.name ||
            this.toAccount
          )
        } else {
          return this.currentConversation.groupProfile.name || this.toAccount
        }
      } else if (this.currentConversation.conversationID === '@TIM#SYSTEM') {
        return '系统通知'
      }
      return this.toAccount
    },

    showMessageSendBox() {
      return this.currentConversation.type !== this.TIM.TYPES.CONV_SYSTEM
    }
  },
  mounted() {
    this.$bus.$on('image-loaded', this.onImageLoaded)
    this.$bus.$on('scroll-bottom', this.scrollMessageListToButtom)
    if (this.currentConversation.conversationID === '@TIM#SYSTEM') {
      return false
    }
  },
  updated() {
    this.keepMessageListOnButtom()
    // 1. 系统会话隐藏右侧资料组件
    // 2. 没有当前会话时，隐藏右侧资料组件。
    //    背景：退出群组/删除会话时，会出现一处空白区域
    if (
      this.currentConversation.conversationID === '@TIM#SYSTEM' ||
      typeof this.currentConversation.conversationID === 'undefined'
    ) {
      this.showConversationProfile = false
    }
  },
  watch: {
    currentUnreadCount(next) {
      if (!this.hidden && next > 0) {
        this.tim.setMessageRead({ conversationID: this.currentConversation.conversationID })
      }
    },
    hidden(next) {
      if (!next && this.currentUnreadCount > 0) {
        this.tim.setMessageRead({ conversationID: this.currentConversation.conversationID })
      }
    }
  },
  methods: {
    onScroll({ target: { scrollTop } }) {
      const messageListNode = this.$refs['message-list']
      if (!messageListNode) {
        return
      }
      if (this.preScrollHeight - messageListNode.clientHeight - scrollTop < 20) {
        this.isShowScrollButtomTips = false
      }
    },
    // 如果滚到底部就保持在底部，否则提示是否要滚到底部
    keepMessageListOnButtom() {
      const messageListNode = this.$refs['message-list']
      if (!messageListNode) {
        return
      }
      // 距离底部20px内强制滚到底部,否则提示有新消息
      if (this.preScrollHeight - messageListNode.clientHeight - messageListNode.scrollTop < 20) {
        this.$nextTick(() => {
          messageListNode.scrollTop = messageListNode.scrollHeight
        })
        this.isShowScrollButtomTips = false
      } else {
        this.isShowScrollButtomTips = true
      }
      this.preScrollHeight = messageListNode.scrollHeight
    },
    // 直接滚到底部
    scrollMessageListToButtom() {
      this.$nextTick(() => {
        const messageListNode = this.$refs['message-list']
        if (!messageListNode) {
          return
        }
        messageListNode.scrollTop = messageListNode.scrollHeight
        this.preScrollHeight = messageListNode.scrollHeight
        this.isShowScrollButtomTips = false
      })
    },
    showMore() {
      this.showConversationProfile = !this.showConversationProfile
    },
    onImageLoaded() {
      this.keepMessageListOnButtom()
    },
    handleConversationDown() {
      // 到计时逻辑 点击计时 调用发送信息接口 触发列表函数 根据当前会话列表的id 去查refs列表对应的index 然后用index去调用refs对应项目的5分钟到计时函数 时间到了就调用关闭接口
      // todo 调结束对话接口 ——》 成功后 开始到计时5分钟  展示结束信息  -》到计时结束 ——》 后端发送信息
      // 发送 自定义结束
      // console.log('this.currentConversation',this.currentConversation)
      // 只有组可以退出
      // TUDO 10s内连续点击不可触发
      if (!this.conversationDownLoading) {
        // console.log('可触发')
        const currentConversationid = this.currentConversation.conversationID
        if (this.currentConversation.type === 'GROUP') {
          const parms = {
            userId: this.userID,
            groupId: this.currentConversation.groupProfile.groupID,
            type: 'SERVER_END',
            groupName: this.name
          }
          handleCustomizeExitInformation(parms).then(res => {
            if (res) {
              // 传入点击时候的会话id
              // 5分钟倒计时后端处理 考虑到5分钟内关闭网页 以及5分钟内继续聊天 无法监控
              // this.$bus.$emit('handleConversationItemClick', currentConversationid)
            }
          })
        }
      }
      // 没有定时器 设置10s定时器
      if (!this.timer) {
        this.conversationDownLoading = true
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          //  console.log('清楚 可触发')
          clearTimeout(this.timer)
          this.timer = null
          this.conversationDownLoading = false
        }, 1000 * 5)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.nochat-txt
  color #333
  font-size 16px
  text-align center
  margin-top:30px
.nochat-txt1
  color #333
  font-size 20px
  text-align center
  margin-top 10px
  font-weight:bold
.no-chatImg
  width 464px
  height 233px
.no-chatWrap
  width 464px
  margin 22vh auto 0
/* 当前会话的骨架屏 */
.current-conversation-wrapper
  height $height
  background-color $background-light
  color $base
  display flex
  border-radius 16px
  .current-conversation
    display: flex;
    flex-direction: column;
    width: 100%;
    height: $height;
  .profile
    background-color:#fff
    height: $height;
    overflow-y: scroll;
    width 350px
    border-left 1px solid $border-base
    flex-shrink 0
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2)
    border-radius: 16px
  .more
    display: flex;
    justify-content: center;
    font-size: 12px;
  .no-more
    display: flex;
    justify-content: center;
    color: $secondary;
    font-size: 12px;
    padding: 10px 10px;

.header
  border-bottom 1px solid $border-base
  height 70px
  position relative
  .people-count
    padding:6px 20px 0
    display flex
    align-items center
    .people-icon
      display inline-block
      width 14px
      height 14px
      background url('../../../assets/people-icon.png') no-repeat
      background-size 100%
    .people-num
      display inline-block
      color #999
      font-size 14px
      margin-left 4px
  .name
    padding 12px 20px 0
    color #333
    font-size 16px
    font-weight bold
   // text-shadow $font-dark 0 0 0.1em
  .btn-more-info
    position absolute
    top 25px
    right -330px
    width 20px
    height 20px
    cursor pointer
    background url('../../../assets/service_msg_icon_close.png') no-repeat
    background-size 100%
    &.left-arrow
      right 25px
      background url('../../../assets/service_msg_icon_set.png') no-repeat
      background-size 100%
  .header-conversation-down
    display: flex;
    justify-content: space-around;
    align-items: center;
    position absolute
    top 20px
    right 57px
    width: 90px;
    height: 32px;
    line-height 32px
    background: #FFFFFF;
    border-radius: 6px;
    cursor pointer
    color:#18A4FF
    text-align :center
    .header-conversation-down-cion
      position relative
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #009CFF;
      box-shadow: 0px 0px 2px 0px rgba(0, 156, 255, 0.8);
      &::before
        content: "";
        position: absolute;
        top: 6px;
        left: 6px;
        width: 8px;
        height: 8px;
        background: #FFFFFF;
        border-radius: 2px;
  .header-conversation-down-display
    cursor:not-allowed;
.content
  display: flex;
  flex 1
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  .message-list
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 0 20px;
  .newMessageTips
    position: absolute
    cursor: pointer;
    padding: 5px;
    width: 120px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 5px;
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
    border: $border-light 1px solid;
    background-color: $white;
    color: $primary;
.footer
  border-top: 1px solid $border-base;
.show-more {
  text-align: right;
  padding: 10px 20px 0 0;
}
</style>
