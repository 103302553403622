<template>
  <div class="list-container">
    <div class="header-top">
      <div class="filter-wrap">
         <a-popover v-model="visiblePopover"  overlayClassName="classPopover" trigger="click" placement="bottom">
              <template slot="content">
                <div>筛选条件</div>
                <div class="sel-item">
                  <a-select
                    show-search
                    v-model="timezoneStd"
                    :filter-option="filterOption"
                    option-filter-prop="children"
                    size="small"
                    placeholder="时区"
                    allowClear
                    style="width:200px;"
                    >
                  <a-select-option :value="i.value" v-for="i of timezones" :key="i.label">
                    {{ i.label }}
                  </a-select-option>
                  </a-select>
                </div>
                <div class="sel-item">
                   <a-select
                    show-search
                    :filter-option="filterOption"
                    option-filter-prop="children"
                    size="small"
                    v-model="reminder"
                    placeholder="剩余课时"
                    style="width:200px;"
                    allowClear
                    >
                  <a-select-option :value="i.value" v-for="i of reminderArr" :key="i.label">
                    {{ i.label }}
                  </a-select-option>
                  </a-select>
                </div>
                <div class="sel-item">
                   <a-select
                    show-search
                    @search="searchClassAdmin"
                    size="small"
                    v-model="ownerId"
                    :filter-option="filterOption"
                    option-filter-prop="children"
                    placeholder="班主任姓名"
                    style="width:200px;"
                    allowClear
                    
                    >

                  <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                    {{ i.fullName }}
                  </a-select-option>
                  </a-select>
                </div>
                <div class="sel-item" style="text-align:center;margin-top:20px">
                  <span class="sel-btn reset-btn" @click="resetSelect">重置</span>
                  <span class="sel-btn filter-selbtn" @click="searchByName('select')">筛选</span>
                </div>
               </template>
               <img class="filter-btn" v-show="!timezoneStd && !reminder && !ownerId" src="@/assets/icon_filter.png">
               <img class="filter-btn" v-show="timezoneStd || reminder || ownerId" src="@/assets/filter-btngreen.png">
               <span :class="(timezoneStd || reminder || ownerId)?'greenColor':'grayColor'">筛选</span>
          </a-popover>
        </div>
      <div class="header-search">
         <el-input
            :clearable=true
            class="header-input"
            placeholder="姓名、学号、或邮箱"
            v-model="studentName"
            @keydown.enter.native="searchByName('search')"
          />
          <span class="header-btn" type="primary" @click="searchByName('search')">搜索</span>
      </div>
    </div>

    <div class="studenlist-wrap">
      <span style="font-size:16px;font-weight:600;color:#333">学生</span>
      <span style="margin-left:auto;font-size:14px;color:#666">共{{onlineLabel}}个</span>
    </div>
   
        <div class="screen-wrap" v-show="showScreen">
          <div class="screen-result">筛选结果（{{ studentList.length }}）</div>
          <div class="screen-clear" @click="searchByName('clear')">清空筛选条件</div>
        </div>
        <div class="scroll-container" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <student-item v-for="student in studentList" :key="student.groupID" :group="student" />
        </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getStudentInfo,getStudentInfoPage,timezones,selClassAdmin} from '@/api/headTeacher';
import StudentItem from './student-item.vue';

export default {
  data() {
    return {
      visiblePopover:false,
      classAdminList:[],
      reminderArr:[
        {label:'中文剩余课时小于8',value:'REMINDER_LT8_CHINESE'},
        {label:'中文剩余课时大于等于8',value:'REMINDER_GE8_CHINESE'},
        {label:'数学剩余课时小于8',value:'REMINDER_LT8_MATH'},
        {label:'数学剩余课时大于等于8',value:'REMINDER_GE8_MATH'},
      ],
      timezones:null,
      timezoneStd:undefined,
      reminder:undefined,
      ownerId:undefined,
      currentPage: 1,
      pageSize:20,
      busy: false,
      showScreen: false,
      searchLoading: false,
      groupID: '',
      hideSearchLoading: true,
      oldLabel: '已转出',
      screenCount: 0,
      stretch: true,
      activeName: 'online',
      showDialog: false,
      userID: '',
      isCheckouting: false, // 是否正在切换会话
      timeout: null,
      totalCount: 0,
      allStudentList:[],
      onlineLabel:0
    };
  },

  components: {
    StudentItem,
  },
  computed: {
    ...mapState({
      searchStudentName: (state) => {
        return state.student.searchStudentName;
      },
      studentList: (state) => state.student.studentList,
      conversationList: (state) => state.conversation.conversationList,
      currentConversation: (state) => state.conversation.currentConversation,
    }),
    studentName: {
      get() {
        return this.searchStudentName;
      },
      set(val) {
        this.$store.commit('setStudentName', val);
      },
    },
  },

  methods: {
   filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.indexOf(input) >= 0
      );
    },
  resetSelect(){
     this.timezoneStd=undefined;
     this.reminder=undefined;
     this.ownerId=undefined;
     this.visiblePopover=false
  },
  changeTimezones(val){
    this.timezoneStd=val
  },
  changeReminder(val){
    this.reminder=val
  },
  changeClassAdmin(val){
    this.ownerId=val
  },
     loadMore() {
      this.busy = true
      setTimeout(() => {
       this.currentPage++;
         this.getStudentInfo()
      }, 1000)
    },
  searchClassAdmin(name){
    let params={
      name:name
    }
    selClassAdmin(params).then(res=>{
      this.classAdminList=res.data.content
    })
  },
  //  filterOption(input, option) {
  //     return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  //   },
    handleTabClick() {},
    // 搜索学生姓名
    searchByName(type) {
      if(this.searchLoading){
        return
      }
      if (type === 'clear') {
        this.currentPage=1
        // 点击清空搜索条件
        this.showScreen = false;
        this.timezoneStd=undefined;
        this.reminder=undefined;
        this.ownerId=undefined;
        this.$store.commit('setStudentName', '');
         this.allStudentList=[]
        this.getStudentInfo();
      } else if (type === 'index') {
        this.getStudentInfo();
      }else if(type === 'search'){

          if (!this.studentName) {
          this.$store.commit('showMessage', {
            message: '请输入学生姓名',
            type: 'warning',
          });
          return;
        }
        this.showScreen = true;
        this.allStudentList=[]
        this.currentPage=1;
        this.getStudentInfo('search');
      }else if(type === 'select'){
        this.visiblePopover=false
        this.showScreen = true;
        this.allStudentList=[]
        this.currentPage=1;
        this.getStudentInfo('search');
      } 
     
    },
     // 搜索服务学生列表
    getStudentInfo(act) {
      
      this.searchLoading = true;
      const params = {
        name: this.studentName,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        timezoneStd:this.timezoneStd,
        reminder:this.reminder,
        ownerId:this.ownerId
      };

      getStudentInfoPage(params).then((res) => {
        const data = res.data.content;
        const studentList = data
        this.onlineLabel = res.data.totalCount;
        if (studentList.length === 0) {
          if(act==='search'){
             this.$store.commit('showMessage', {
            message: '没有找到该学生',
            type: 'error',
          });
          this.screenCount = 0;
          this.$store.commit('updateStudentList', []);
          }else{
             this.screenCount =this.allStudentList.length;
            this.$store.commit('updateStudentList', this.allStudentList);

          }
          //滚动关闭
         this.busy =true
        } else {
          this.screenCount = studentList.length;
          let arr=this.allStudentList.concat(studentList)
          this.$store.commit('updateStudentList',arr);
           this.allStudentList=arr;
            this.busy = false
        }
        this.searchLoading = false;
      }).catch(()=>{
        this.searchLoading=false
         this.busy =true
      });

    },
    // 搜索服务学生列表
  //   getStudentInfo() {
  //     this.searchLoading = true;
  //     const params = {
  //       name: this.studentName,
  //     };

  //     getStudentInfo(params).then((res) => {
  //       const data = res.data.content;
  //       const studentList = data.list;
  //       this.onlineLabel = `我的学生（${data.totalCount}）`;
  //       if (studentList.length === 0) {
  //         this.$store.commit('showMessage', {
  //           message: '没有找到该学生',
  //           type: 'error',
  //         });
  //         this.screenCount = 0;
  //         this.$store.commit('updateStudentList', []);
  //       } else {
  //         this.screenCount = studentList.length;
  //         this.$store.commit('updateStudentList', studentList);
  //       }
  //       this.searchLoading = false;
  //     });
  //   },
  },
  mounted() {
    //代表从组件模块点击他的学生
    if(this.$route.params.name){
        this.ownerId=this.$route.params.uuid;
        this.searchClassAdmin(this.$route.params.name)
    }
    // 如果从表格学生点击进来，显示筛选结果
    if (this.searchStudentName) {
      this.showScreen = true;
      this.searchByName('search');
    } else {
      this.showScreen = false;
      this.searchByName('index');
    }
    
    //获取时区筛选下拉列表
    timezones().then((i) => {
        this.timezones = i.data.content;
      });
  },
};
</script>

<style lang="stylus" scoped>
.el-button--primary
  margin:1px
.studenlist-wrap
  display: flex
  margin-top:23px
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 12px 0 0;
    width: 3px;
    height:16px;
    background: #43D186;
    border-radius: 0px 2px 2px 0px; 
  }
/deep/ .el-input__inner
  border:none!important
  height: 32px;
  line-height: 32px;
  padding-right:10px;
  border-radius: 10px;
  font-size:13px
/deep/ .el-button--primary
  border-radius:8px;
  background-color:#41CC83;
  border-color:#41CC83;

.screen-wrap
  margin-top:10px
  display :flex
  justify-content :space-between
  .screen-clear
      padding:1px 6px
      color:#c3c3c3
      border:1px solid #c3c3c3
      border-radius:30px
      cursor:pointer
.header-top
    display:flex
    margin 10px 0 0
.header-search
    display: flex
    border-radius: 10px;
    align-items: center;
    border: 1px solid  #DCDFE6;
.list-container
  height 88vh
  width 100%
  padding:0 20px
  display flex
  flex-direction column // -reverse
  .header-bar
    flex-shrink 0
    height 50px
    border-bottom 1px solid $background-deep-dark
    padding 10px 10px 10px 20px

  .scroll-container
    margin-top:10px
    height 70vh
    overflow-y scroll
    overflow-x: hidden
    flex 1
.bottom-circle-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.refresh {
  bottom: 70px;
}
.filter-btn{
  width 20px;
  height: 20px;
  padding-right:3px;
}
.filter-wrap{
  width:68px;
  height: 36px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #dcdfe6;
  margin-right: 10px;
  cursor: pointer;
}
.header-btn{
    width: 56px;
    color: #fff;
    background: #43d186;
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    text-align: center;
    border-radius: 8px;
    cursor:pointer;
}
/deep/ .el-input__suffix{
  top:-4px
}
.sel-item{
  margin-top:10px;
}
.sel-btn{
  padding:5px 10px;
  font-size: 13px;
  border-radius: 5px;
  cursor:pointer
}
.reset-btn{
    border: 1px solid #dcdfe6;
    margin-right: 15px;
}
.filter-selbtn{
  background-color: #43D186;
   border: 1px solid #43D186;
   color: #fff;
}
.greenColor{
  color:#43d186
}
.grayColor{
  color:#333
}
</style>
