<template>
  <div v-if="showCurrentConversation">
    <div class="student-topWrap">
      <div class="student-avtor">
        <avatar :src="avatar" :type="currentStudentProfile.type" />
      </div>
      <div class="student-basicInfo">
        <div class="student-name" @click="hrefMyStudent">
          <span style="padding-right: 5px">{{ currentStudentProfile.fullName }}</span
          ><img src="@/assets/service_icon_enter.png" class="icon_enter" />
        </div>
        <div class="student-area">
          <img src="@/assets/service_icon_timezone.png" class="timezone-img" />{{
            getTimezoneName(currentStudentProfile.timezoneStd)
          }}
        </div>
        <!-- <div class="look-btnWrap" @click="hrefSF">
          <span class="look-btn">查看学生信息</span><img src="@/assets/service_icon_enter.png" class="icon_enter" />
        </div> -->
      </div>
    </div>
    <div class="growth-title">增长活动</div>
    <share-link :studentProfile="currentStudentProfile"></share-link>
    <div class="share-btnWrap share-poster" @click="handleSharePoster">
      <div class="icon_enter" />
      <span class="share-title">分享海报</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getFormListByName } from '@/api/poster';
import ShareLink from './ShareLink.vue';
import { getTimezoneName } from '@/utils/geoLocation';

export default {
  components: { ShareLink },
  data() {
    return {
      getTimezoneName,
      hover: false,
      modalVisible: false,
      shareChineseLink: '',
      shareMathLink: '',
      shareLinkTitle: '',
      shareLinkName: '',
      shareLink: '',
    };
  },

  async created() {
    // 根据
    getFormListByName('班主任分享中文链接').then((res) => {
      const { content } = res.data;
      this.shareChineseLink = content[0]?.nodeContent?.url || '';
    });
    getFormListByName('班主任分享数学链接').then((res) => {
      const { content } = res.data;
      this.shareMathLink = content[0]?.nodeContent?.url || '';
    });
  },
  computed: {
    ...mapState({
      currentStudentProfile: (state) => {
        return state.userIM.currentStudentProfile;
      },
      // 是否显示当前会话组件
      showCurrentConversation() {
        return !!this.currentStudentProfile.groupID;
      },
    }),
    avatar: function () {
      return this.currentStudentProfile.headImageUrl || 'https://cdnwukong.com/images/public/wukong-student.png';

      // switch (this.currentStudentProfile.type) {
      //   case 'GROUP':
      //     return this.currentStudentProfile.headImageUrl || 'https://cdnwukong.com/images/public/wukong-student.png'
      //   case 'C2C':
      //     return (
      //       this.currentStudentProfile.userProfile.avatar || 'https://cdnwukong.com/images/public/wukong-student.png'
      //     )
      //   default:
      //     return ''
      // }
    },
  },
  methods: {
    hrefMyStudent() {
      this.$store.commit('setStudentName', this.currentStudentProfile.fullName);
      this.$store.commit('setStudentIdRoute', this.currentStudentProfile.uuid);

      this.$router.push({ path: '/studentDetail', query: { uuid: this.currentStudentProfile.uuid } });
    },
    hrefSF() {
      window.open(`${process.env.VUE_APP_API_SF}/${this.currentStudentProfile.sfId}/view`);
    },
    handleSharePoster() {
      this.$router.push({ path: '/poster' });
    },
    copyLink(url) {
      const input = document.createElement('input');
      input.value = url;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
    },
    handleChineseLink() {
      if (!this.shareChineseLink) {
        this.$message.info('分享链接尚未配置，请联系相关人员进行配置！');
        return;
      }
      const url = `${this.shareChineseLink}&code=${this.currentStudentProfile.code}`;
      this.copyLink(url);
      this.modalVisible = true;
      this.shareLink = url;
      this.shareLinkTitle = '中文专属链接已复制到粘贴板';
    },
    handleMathLink() {
      if (!this.shareMathLink) {
        this.$message.info('分享链接尚未配置，请联系相关人员进行配置！');
        return;
      }
      const url = `${this.shareMathLink}&code=${this.currentStudentProfile.code}&lang=en-US`;
      this.copyLink(url);
      this.modalVisible = true;
      this.shareLink = url;
      this.shareLinkTitle = '数学专属链接已复制到粘贴板';
    },
  },
};
</script>

<style lang="stylus" scoped>
.icon_enter
  width 12px
  height 12px
.timezone-img
  width 12px
  height 12px
  display inline-block
  margin-right 5px
.student-topWrap
  display:flex
  width:450px
  .student-basicInfo
    margin-left:5px
    .student-name
      font-size 16px
      color:#333
      font-weight:bold
      cursor pointer
      display:flex
      align-items: center
    .student-area
      color:#666
      font-size:12px
      margin-top 6px
      display flex
      align-items center
  .look-btnWrap
     margin:10px 0 0 0
     color:#333
     font-size:13px
     width 119px
     height 36px
     border-radius: 10px;
     border: 1px solid #CCCCCC;
     display flex
     align-items center
     justify-content center
     cursor pointer
     .icon_enter
        width 12px
        height 12px
        margin-left 5px


.student-avtor
  width:80px
  height:80px

.growth-title
  padding-top: 20px
  border-top: 1px solid #EEEEEE
  font-size: 14px
  font-weight: 600
  color: #333333
  line-height: 20px
  margin-top: 20px
  margin-bottom: 13px

.share-flex


.share-btnWrap
  height: 36px
  background: #F7F7F7
  border-radius: 10px
  line-height: 36px
  padding: 0 10px
  cursor: pointer

  .icon_enter
    width: 18px
    height: 18px
    margin-right: 5px
.share-poster
  display: flex;
  align-items: center;
  margin-right: 20px
  width: 100px;
  margin-top: 16px;
  .icon_enter
    background: url('~@/assets/service_msg_icon_poster.png') no-repeat center
    background-size:100% 100%
  &:hover
    background:#43D186
    span
      color: #FFFFFF
    .icon_enter
      background: url('~@/assets/service_msg_icon_poster_ed.png') no-repeat center
.share-title
  font-size: 13px
  font-weight: 400
  color:#333
  line-height: 18px
.share-link
  &:hover
    background:#43D186
    border-radius: 10px
    border: 1px solid #009CFF
.item-link
  height: 36px
  text-align: center
  line-height 36px
  cursor: pointer
  &:hover
    background: #009CFF
    color: #FFFFFF
.math-link
  &:hover
    border-radius: 0 0 10px 10px
.share-link-title
  font-size: 18px
  font-weight: 600
  color: #333333
  line-height: 18px
  magrin-bottom: 10px
.share-link-name
  font-size: 16px
  font-weight: 400
  color: #333333
  line-height: 16px
  margin-top: 25px
  margin-bottom: 15px
.share-link-url
  font-size: 16px
  background: #F4F4F4
  border-radius: 2px
  border: 1px solid #DDDDDD
  color: #999
  min-height 30px
  padding: 0 5px
</style>
